//
// general.scss
//
@each $name,
$color in $colors {
    .text-#{$name} {
        color: #{$color} !important;
    }
}

@each $name,
$color in $colors {
    .bg-#{$name} {
        background-color: #{$color} !important;
    }
}
.bg-gradient {
    background: $bg-gradient;
}

.section {
    padding-top: 95px;
    padding-bottom: 95px;
}
.home-bottom-shape {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

.font-weight-medium {
    font-weight: 500;
}
.font-weight-600 {
    font-weight: 600;
}

.f-12 {
    font-size: 12px;
}

.f-13 {
    font-size: 13px;
}

.f-14 {
    font-size: 14px;
}

.f-15 {
    font-size: 15px;
}

.f-16 {
    font-size: 16px;
}

.f-17 {
    font-size: 17px;
}

.f-18 {
    font-size: 18px;
}

.f-19 {
    font-size: 19px;
}

.f-20 {
    font-size: 20px;
}

.line-height-1_4{
    line-height: 1.4;
}
.line-height-1_6{
    line-height: 1.6;
}
.line-height-1_8{
    line-height: 1.8;
}

.badge {
    padding: 7px 8px;
    font-size: 80%;
    text-transform: uppercase;
    font-weight: 500;
}

.home-center {
    display: table;
    width: 100%;
    height: 100%;
}

.home-desc-center {
    display: table-cell;
    vertical-align: middle;
}