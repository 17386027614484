/*
 Template Name: Modzy - Responsive Bootstrap 4 Admin Dashboard
 Author: Themesdesign
 File: Icons
 */
/*
* Remix Icon v1.3.1
* https://remixicon.com
* https://github.com/Remix-Design/RemixIcon
*
* Copyright RemixIcon.com
* Released under the Apache License Version 2.0
*
* Date: 2019-7-6
*/
@font-face {
  font-family: "remixicon";
  src: url("../fonts/remixicon.eot?t=1562401252720");
  /* IE9*/
  src: url("../fonts/remixicon.eot?t=1562401252720#iefix") format("embedded-opentype"), url("../fonts/remixicon.woff2?t=1562401252720") format("woff2"), url("../fonts/remixicon.woff?t=1562401252720") format("woff"), url("../fonts/remixicon.ttf?t=1562401252720") format("truetype"), url("../fonts/remixicon.svg?t=1562401252720#remixicon") format("svg");
  /* iOS 4.1- */
  font-display: swap;
}

[class^="remixicon-"], [class*=" remixicon-"] {
  font-family: 'remixicon' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ri-lg {
  font-size: 1.3333em;
  line-height: 0.75em;
  vertical-align: -.0667em;
}

.ri-xl {
  font-size: 1.5em;
  line-height: 0.6666em;
  vertical-align: -.075em;
}

.ri-xxs {
  font-size: .5em;
}

.ri-xs {
  font-size: .75em;
}

.ri-sm {
  font-size: .875em;
}

.ri-1x {
  font-size: 1em;
}

.ri-2x {
  font-size: 2em;
}

.ri-3x {
  font-size: 3em;
}

.ri-4x {
  font-size: 4em;
}

.ri-5x {
  font-size: 5em;
}

.ri-6x {
  font-size: 6em;
}

.ri-7x {
  font-size: 7em;
}

.ri-8x {
  font-size: 8em;
}

.ri-9x {
  font-size: 9em;
}

.ri-10x {
  font-size: 10em;
}

.ri-fw {
  text-align: center;
  width: 1.25em;
}

.remixicon-4k-fill:before {
  content: "\ea01";
}

.remixicon-4k-line:before {
  content: "\ea02";
}

.remixicon-account-box-fill:before {
  content: "\ea03";
}

.remixicon-account-box-line:before {
  content: "\ea04";
}

.remixicon-account-circle-fill:before {
  content: "\ea05";
}

.remixicon-account-circle-line:before {
  content: "\ea06";
}

.remixicon-account-pin-box-fill:before {
  content: "\ea07";
}

.remixicon-account-pin-box-line:before {
  content: "\ea08";
}

.remixicon-account-pin-circle-fill:before {
  content: "\ea09";
}

.remixicon-account-pin-circle-line:before {
  content: "\ea0a";
}

.remixicon-add-box-fill:before {
  content: "\ea0b";
}

.remixicon-add-box-line:before {
  content: "\ea0c";
}

.remixicon-add-circle-fill:before {
  content: "\ea0d";
}

.remixicon-add-circle-line:before {
  content: "\ea0e";
}

.remixicon-add-fill:before {
  content: "\ea0f";
}

.remixicon-add-line:before {
  content: "\ea10";
}

.remixicon-admin-fill:before {
  content: "\ea11";
}

.remixicon-admin-line:before {
  content: "\ea12";
}

.remixicon-airplay-fill:before {
  content: "\ea13";
}

.remixicon-airplay-line:before {
  content: "\ea14";
}

.remixicon-alarm-fill:before {
  content: "\ea15";
}

.remixicon-alarm-line:before {
  content: "\ea16";
}

.remixicon-alarm-warning-fill:before {
  content: "\ea17";
}

.remixicon-alarm-warning-line:before {
  content: "\ea18";
}

.remixicon-album-fill:before {
  content: "\ea19";
}

.remixicon-album-line:before {
  content: "\ea1a";
}

.remixicon-alert-fill:before {
  content: "\ea1b";
}

.remixicon-alert-line:before {
  content: "\ea1c";
}

.remixicon-align-bottom:before {
  content: "\ea1d";
}

.remixicon-align-center:before {
  content: "\ea1e";
}

.remixicon-align-justify:before {
  content: "\ea1f";
}

.remixicon-align-left:before {
  content: "\ea20";
}

.remixicon-align-right:before {
  content: "\ea21";
}

.remixicon-align-top:before {
  content: "\ea22";
}

.remixicon-align-vertically:before {
  content: "\ea23";
}

.remixicon-alipay-fill:before {
  content: "\ea24";
}

.remixicon-alipay-line:before {
  content: "\ea25";
}

.remixicon-amazon-fill:before {
  content: "\ea26";
}

.remixicon-amazon-line:before {
  content: "\ea27";
}

.remixicon-anchor-fill:before {
  content: "\ea28";
}

.remixicon-anchor-line:before {
  content: "\ea29";
}

.remixicon-android-fill:before {
  content: "\ea2a";
}

.remixicon-android-line:before {
  content: "\ea2b";
}

.remixicon-angularjs-fill:before {
  content: "\ea2c";
}

.remixicon-angularjs-line:before {
  content: "\ea2d";
}

.remixicon-anticlockwise-2-fill:before {
  content: "\ea2e";
}

.remixicon-anticlockwise-2-line:before {
  content: "\ea2f";
}

.remixicon-anticlockwise-fill:before {
  content: "\ea30";
}

.remixicon-anticlockwise-line:before {
  content: "\ea31";
}

.remixicon-apple-fill:before {
  content: "\ea32";
}

.remixicon-apple-line:before {
  content: "\ea33";
}

.remixicon-apps-2-fill:before {
  content: "\ea34";
}

.remixicon-apps-2-line:before {
  content: "\ea35";
}

.remixicon-apps-fill:before {
  content: "\ea36";
}

.remixicon-apps-line:before {
  content: "\ea37";
}

.remixicon-archive-drawer-fill:before {
  content: "\ea38";
}

.remixicon-archive-drawer-line:before {
  content: "\ea39";
}

.remixicon-archive-fill:before {
  content: "\ea3a";
}

.remixicon-archive-line:before {
  content: "\ea3b";
}

.remixicon-arrow-down-circle-fill:before {
  content: "\ea3c";
}

.remixicon-arrow-down-circle-line:before {
  content: "\ea3d";
}

.remixicon-arrow-down-fill:before {
  content: "\ea3e";
}

.remixicon-arrow-down-line:before {
  content: "\ea3f";
}

.remixicon-arrow-down-s-fill:before {
  content: "\ea40";
}

.remixicon-arrow-down-s-line:before {
  content: "\ea41";
}

.remixicon-arrow-drop-down-fill:before {
  content: "\ea42";
}

.remixicon-arrow-drop-down-line:before {
  content: "\ea43";
}

.remixicon-arrow-drop-left-fill:before {
  content: "\ea44";
}

.remixicon-arrow-drop-left-line:before {
  content: "\ea45";
}

.remixicon-arrow-drop-right-fill:before {
  content: "\ea46";
}

.remixicon-arrow-drop-right-line:before {
  content: "\ea47";
}

.remixicon-arrow-drop-up-fill:before {
  content: "\ea48";
}

.remixicon-arrow-drop-up-line:before {
  content: "\ea49";
}

.remixicon-arrow-go-back-fill:before {
  content: "\ea4a";
}

.remixicon-arrow-go-back-line:before {
  content: "\ea4b";
}

.remixicon-arrow-go-forward-fill:before {
  content: "\ea4c";
}

.remixicon-arrow-go-forward-line:before {
  content: "\ea4d";
}

.remixicon-arrow-left-circle-fill:before {
  content: "\ea4e";
}

.remixicon-arrow-left-circle-line:before {
  content: "\ea4f";
}

.remixicon-arrow-left-down-fill:before {
  content: "\ea50";
}

.remixicon-arrow-left-down-line:before {
  content: "\ea51";
}

.remixicon-arrow-left-fill:before {
  content: "\ea52";
}

.remixicon-arrow-left-line:before {
  content: "\ea53";
}

.remixicon-arrow-left-s-fill:before {
  content: "\ea54";
}

.remixicon-arrow-left-s-line:before {
  content: "\ea55";
}

.remixicon-arrow-left-up-fill:before {
  content: "\ea56";
}

.remixicon-arrow-left-up-line:before {
  content: "\ea57";
}

.remixicon-arrow-right-circle-fill:before {
  content: "\ea58";
}

.remixicon-arrow-right-circle-line:before {
  content: "\ea59";
}

.remixicon-arrow-right-down-fill:before {
  content: "\ea5a";
}

.remixicon-arrow-right-down-line:before {
  content: "\ea5b";
}

.remixicon-arrow-right-fill:before {
  content: "\ea5c";
}

.remixicon-arrow-right-line:before {
  content: "\ea5d";
}

.remixicon-arrow-right-s-fill:before {
  content: "\ea5e";
}

.remixicon-arrow-right-s-line:before {
  content: "\ea5f";
}

.remixicon-arrow-right-up-fill:before {
  content: "\ea60";
}

.remixicon-arrow-right-up-line:before {
  content: "\ea61";
}

.remixicon-arrow-up-circle-fill:before {
  content: "\ea62";
}

.remixicon-arrow-up-circle-line:before {
  content: "\ea63";
}

.remixicon-arrow-up-fill:before {
  content: "\ea64";
}

.remixicon-arrow-up-line:before {
  content: "\ea65";
}

.remixicon-arrow-up-s-fill:before {
  content: "\ea66";
}

.remixicon-arrow-up-s-line:before {
  content: "\ea67";
}

.remixicon-artboard-2-fill:before {
  content: "\ea68";
}

.remixicon-artboard-2-line:before {
  content: "\ea69";
}

.remixicon-artboard-fill:before {
  content: "\ea6a";
}

.remixicon-artboard-line:before {
  content: "\ea6b";
}

.remixicon-article-fill:before {
  content: "\ea6c";
}

.remixicon-article-line:before {
  content: "\ea6d";
}

.remixicon-at-fill:before {
  content: "\ea6e";
}

.remixicon-at-line:before {
  content: "\ea6f";
}

.remixicon-attachment-2:before {
  content: "\ea70";
}

.remixicon-attachment-fill:before {
  content: "\ea71";
}

.remixicon-attachment-line:before {
  content: "\ea72";
}

.remixicon-award-fill:before {
  content: "\ea73";
}

.remixicon-award-line:before {
  content: "\ea74";
}

.remixicon-baidu-fill:before {
  content: "\ea75";
}

.remixicon-baidu-line:before {
  content: "\ea76";
}

.remixicon-ball-pen-fill:before {
  content: "\ea77";
}

.remixicon-ball-pen-line:before {
  content: "\ea78";
}

.remixicon-bank-card-2-fill:before {
  content: "\ea79";
}

.remixicon-bank-card-2-line:before {
  content: "\ea7a";
}

.remixicon-bank-card-fill:before {
  content: "\ea7b";
}

.remixicon-bank-card-line:before {
  content: "\ea7c";
}

.remixicon-bank-fill:before {
  content: "\ea7d";
}

.remixicon-bank-line:before {
  content: "\ea7e";
}

.remixicon-bar-chart-2-fill:before {
  content: "\ea7f";
}

.remixicon-bar-chart-2-line:before {
  content: "\ea80";
}

.remixicon-bar-chart-box-fill:before {
  content: "\ea81";
}

.remixicon-bar-chart-box-line:before {
  content: "\ea82";
}

.remixicon-bar-chart-fill:before {
  content: "\ea83";
}

.remixicon-bar-chart-grouped-fill:before {
  content: "\ea84";
}

.remixicon-bar-chart-grouped-line:before {
  content: "\ea85";
}

.remixicon-bar-chart-horizontal-fill:before {
  content: "\ea86";
}

.remixicon-bar-chart-horizontal-line:before {
  content: "\ea87";
}

.remixicon-bar-chart-line:before {
  content: "\ea88";
}

.remixicon-barcode-box-fill:before {
  content: "\ea89";
}

.remixicon-barcode-box-line:before {
  content: "\ea8a";
}

.remixicon-barcode-fill:before {
  content: "\ea8b";
}

.remixicon-barcode-line:before {
  content: "\ea8c";
}

.remixicon-barricade-fill:before {
  content: "\ea8d";
}

.remixicon-barricade-line:before {
  content: "\ea8e";
}

.remixicon-base-station-fill:before {
  content: "\ea8f";
}

.remixicon-base-station-line:before {
  content: "\ea90";
}

.remixicon-battery-2-charge-fill:before {
  content: "\ea91";
}

.remixicon-battery-2-charge-line:before {
  content: "\ea92";
}

.remixicon-battery-2-fill:before {
  content: "\ea93";
}

.remixicon-battery-2-line:before {
  content: "\ea94";
}

.remixicon-battery-charge-fill:before {
  content: "\ea95";
}

.remixicon-battery-charge-line:before {
  content: "\ea96";
}

.remixicon-battery-fill:before {
  content: "\ea97";
}

.remixicon-battery-line:before {
  content: "\ea98";
}

.remixicon-battery-low-fill:before {
  content: "\ea99";
}

.remixicon-battery-low-line:before {
  content: "\ea9a";
}

.remixicon-behance-fill:before {
  content: "\ea9b";
}

.remixicon-behance-line:before {
  content: "\ea9c";
}

.remixicon-bike-fill:before {
  content: "\ea9d";
}

.remixicon-bike-line:before {
  content: "\ea9e";
}

.remixicon-bill-fill:before {
  content: "\ea9f";
}

.remixicon-bill-line:before {
  content: "\eaa0";
}

.remixicon-bit-coin-fill:before {
  content: "\eaa1";
}

.remixicon-bit-coin-line:before {
  content: "\eaa2";
}

.remixicon-bluetooth-connect-fill:before {
  content: "\eaa3";
}

.remixicon-bluetooth-connect-line:before {
  content: "\eaa4";
}

.remixicon-bluetooth-fill:before {
  content: "\eaa5";
}

.remixicon-bluetooth-line:before {
  content: "\eaa6";
}

.remixicon-blur-off-fill:before {
  content: "\eaa7";
}

.remixicon-blur-off-line:before {
  content: "\eaa8";
}

.remixicon-bold:before {
  content: "\eaa9";
}

.remixicon-book-2-fill:before {
  content: "\eaaa";
}

.remixicon-book-2-line:before {
  content: "\eaab";
}

.remixicon-book-3-fill:before {
  content: "\eaac";
}

.remixicon-book-3-line:before {
  content: "\eaad";
}

.remixicon-book-fill:before {
  content: "\eaae";
}

.remixicon-book-line:before {
  content: "\eaaf";
}

.remixicon-book-mark-fill:before {
  content: "\eab0";
}

.remixicon-book-mark-line:before {
  content: "\eab1";
}

.remixicon-book-open-fill:before {
  content: "\eab2";
}

.remixicon-book-open-line:before {
  content: "\eab3";
}

.remixicon-book-read-fill:before {
  content: "\eab4";
}

.remixicon-book-read-line:before {
  content: "\eab5";
}

.remixicon-bookmark-2-fill:before {
  content: "\eab6";
}

.remixicon-bookmark-2-line:before {
  content: "\eab7";
}

.remixicon-bookmark-3-fill:before {
  content: "\eab8";
}

.remixicon-bookmark-3-line:before {
  content: "\eab9";
}

.remixicon-bookmark-fill:before {
  content: "\eaba";
}

.remixicon-bookmark-line:before {
  content: "\eabb";
}

.remixicon-braces-fill:before {
  content: "\eabc";
}

.remixicon-braces-line:before {
  content: "\eabd";
}

.remixicon-brackets-fill:before {
  content: "\eabe";
}

.remixicon-brackets-line:before {
  content: "\eabf";
}

.remixicon-briefcase-2-fill:before {
  content: "\eac0";
}

.remixicon-briefcase-2-line:before {
  content: "\eac1";
}

.remixicon-briefcase-3-fill:before {
  content: "\eac2";
}

.remixicon-briefcase-3-line:before {
  content: "\eac3";
}

.remixicon-briefcase-4-fill:before {
  content: "\eac4";
}

.remixicon-briefcase-4-line:before {
  content: "\eac5";
}

.remixicon-briefcase-5-fill:before {
  content: "\eac6";
}

.remixicon-briefcase-5-line:before {
  content: "\eac7";
}

.remixicon-briefcase-fill:before {
  content: "\eac8";
}

.remixicon-briefcase-line:before {
  content: "\eac9";
}

.remixicon-broadcast-fill:before {
  content: "\eaca";
}

.remixicon-broadcast-line:before {
  content: "\eacb";
}

.remixicon-brush-2-fill:before {
  content: "\eacc";
}

.remixicon-brush-2-line:before {
  content: "\eacd";
}

.remixicon-brush-3-fill:before {
  content: "\eace";
}

.remixicon-brush-3-line:before {
  content: "\eacf";
}

.remixicon-brush-4-fill:before {
  content: "\ead0";
}

.remixicon-brush-4-line:before {
  content: "\ead1";
}

.remixicon-brush-fill:before {
  content: "\ead2";
}

.remixicon-brush-line:before {
  content: "\ead3";
}

.remixicon-bug-2-fill:before {
  content: "\ead4";
}

.remixicon-bug-2-line:before {
  content: "\ead5";
}

.remixicon-bug-fill:before {
  content: "\ead6";
}

.remixicon-bug-line:before {
  content: "\ead7";
}

.remixicon-building-2-fill:before {
  content: "\ead8";
}

.remixicon-building-2-line:before {
  content: "\ead9";
}

.remixicon-building-3-fill:before {
  content: "\eada";
}

.remixicon-building-3-line:before {
  content: "\eadb";
}

.remixicon-building-4-fill:before {
  content: "\eadc";
}

.remixicon-building-4-line:before {
  content: "\eadd";
}

.remixicon-building-fill:before {
  content: "\eade";
}

.remixicon-building-line:before {
  content: "\eadf";
}

.remixicon-bus-2-fill:before {
  content: "\eae0";
}

.remixicon-bus-2-line:before {
  content: "\eae1";
}

.remixicon-bus-fill:before {
  content: "\eae2";
}

.remixicon-bus-line:before {
  content: "\eae3";
}

.remixicon-calculator-fill:before {
  content: "\eae4";
}

.remixicon-calculator-line:before {
  content: "\eae5";
}

.remixicon-calendar-2-fill:before {
  content: "\eae6";
}

.remixicon-calendar-2-line:before {
  content: "\eae7";
}

.remixicon-calendar-event-fill:before {
  content: "\eae8";
}

.remixicon-calendar-event-line:before {
  content: "\eae9";
}

.remixicon-calendar-fill:before {
  content: "\eaea";
}

.remixicon-calendar-line:before {
  content: "\eaeb";
}

.remixicon-calendar-todo-fill:before {
  content: "\eaec";
}

.remixicon-calendar-todo-line:before {
  content: "\eaed";
}

.remixicon-camera-2-fill:before {
  content: "\eaee";
}

.remixicon-camera-2-line:before {
  content: "\eaef";
}

.remixicon-camera-3-fill:before {
  content: "\eaf0";
}

.remixicon-camera-3-line:before {
  content: "\eaf1";
}

.remixicon-camera-fill:before {
  content: "\eaf2";
}

.remixicon-camera-lens-fill:before {
  content: "\eaf3";
}

.remixicon-camera-lens-line:before {
  content: "\eaf4";
}

.remixicon-camera-line:before {
  content: "\eaf5";
}

.remixicon-camera-off-fill:before {
  content: "\eaf6";
}

.remixicon-camera-off-line:before {
  content: "\eaf7";
}

.remixicon-camera-switch-fill:before {
  content: "\eaf8";
}

.remixicon-camera-switch-line:before {
  content: "\eaf9";
}

.remixicon-car-fill:before {
  content: "\eafa";
}

.remixicon-car-line:before {
  content: "\eafb";
}

.remixicon-car-washing-fill:before {
  content: "\eafc";
}

.remixicon-car-washing-line:before {
  content: "\eafd";
}

.remixicon-cast-fill:before {
  content: "\eafe";
}

.remixicon-cast-line:before {
  content: "\eaff";
}

.remixicon-cellphone-fill:before {
  content: "\eb00";
}

.remixicon-cellphone-line:before {
  content: "\eb01";
}

.remixicon-celsius-fill:before {
  content: "\eb02";
}

.remixicon-celsius-line:before {
  content: "\eb03";
}

.remixicon-charging-pile-2-fill:before {
  content: "\eb04";
}

.remixicon-charging-pile-2-line:before {
  content: "\eb05";
}

.remixicon-charging-pile-fill:before {
  content: "\eb06";
}

.remixicon-charging-pile-line:before {
  content: "\eb07";
}

.remixicon-chat-1-fill:before {
  content: "\eb08";
}

.remixicon-chat-1-line:before {
  content: "\eb09";
}

.remixicon-chat-2-fill:before {
  content: "\eb0a";
}

.remixicon-chat-2-line:before {
  content: "\eb0b";
}

.remixicon-chat-3-fill:before {
  content: "\eb0c";
}

.remixicon-chat-3-line:before {
  content: "\eb0d";
}

.remixicon-chat-4-fill:before {
  content: "\eb0e";
}

.remixicon-chat-4-line:before {
  content: "\eb0f";
}

.remixicon-chat-check-fill:before {
  content: "\eb10";
}

.remixicon-chat-check-line:before {
  content: "\eb11";
}

.remixicon-chat-delete-fill:before {
  content: "\eb12";
}

.remixicon-chat-delete-line:before {
  content: "\eb13";
}

.remixicon-chat-download-fill:before {
  content: "\eb14";
}

.remixicon-chat-download-line:before {
  content: "\eb15";
}

.remixicon-chat-forward-fill:before {
  content: "\eb16";
}

.remixicon-chat-forward-line:before {
  content: "\eb17";
}

.remixicon-chat-heart-fill:before {
  content: "\eb18";
}

.remixicon-chat-heart-line:before {
  content: "\eb19";
}

.remixicon-chat-new-fill:before {
  content: "\eb1a";
}

.remixicon-chat-new-line:before {
  content: "\eb1b";
}

.remixicon-chat-settings-fill:before {
  content: "\eb1c";
}

.remixicon-chat-settings-line:before {
  content: "\eb1d";
}

.remixicon-chat-smile-2-fill:before {
  content: "\eb1e";
}

.remixicon-chat-smile-2-line:before {
  content: "\eb1f";
}

.remixicon-chat-smile-3-fill:before {
  content: "\eb20";
}

.remixicon-chat-smile-3-line:before {
  content: "\eb21";
}

.remixicon-chat-smile-fill:before {
  content: "\eb22";
}

.remixicon-chat-smile-line:before {
  content: "\eb23";
}

.remixicon-chat-upload-fill:before {
  content: "\eb24";
}

.remixicon-chat-upload-line:before {
  content: "\eb25";
}

.remixicon-chat-voice-fill:before {
  content: "\eb26";
}

.remixicon-chat-voice-line:before {
  content: "\eb27";
}

.remixicon-check-double-fill:before {
  content: "\eb28";
}

.remixicon-check-double-line:before {
  content: "\eb29";
}

.remixicon-check-fill:before {
  content: "\eb2a";
}

.remixicon-check-line:before {
  content: "\eb2b";
}

.remixicon-checkbox-blank-circle-fill:before {
  content: "\eb2c";
}

.remixicon-checkbox-blank-circle-line:before {
  content: "\eb2d";
}

.remixicon-checkbox-blank-fill:before {
  content: "\eb2e";
}

.remixicon-checkbox-blank-line:before {
  content: "\eb2f";
}

.remixicon-checkbox-circle-fill:before {
  content: "\eb30";
}

.remixicon-checkbox-circle-line:before {
  content: "\eb31";
}

.remixicon-checkbox-fill:before {
  content: "\eb32";
}

.remixicon-checkbox-indeterminate-fill:before {
  content: "\eb33";
}

.remixicon-checkbox-indeterminate-line:before {
  content: "\eb34";
}

.remixicon-checkbox-line:before {
  content: "\eb35";
}

.remixicon-checkbox-multiple-blank-fill:before {
  content: "\eb36";
}

.remixicon-checkbox-multiple-blank-line:before {
  content: "\eb37";
}

.remixicon-checkbox-multiple-fill:before {
  content: "\eb38";
}

.remixicon-checkbox-multiple-line:before {
  content: "\eb39";
}

.remixicon-china-railway-fill:before {
  content: "\eb3a";
}

.remixicon-china-railway-line:before {
  content: "\eb3b";
}

.remixicon-chrome-fill:before {
  content: "\eb3c";
}

.remixicon-chrome-line:before {
  content: "\eb3d";
}

.remixicon-clapperboard-fill:before {
  content: "\eb3e";
}

.remixicon-clapperboard-line:before {
  content: "\eb3f";
}

.remixicon-clipboard-fill:before {
  content: "\eb40";
}

.remixicon-clipboard-line:before {
  content: "\eb41";
}

.remixicon-clockwise-2-fill:before {
  content: "\eb42";
}

.remixicon-clockwise-2-line:before {
  content: "\eb43";
}

.remixicon-clockwise-fill:before {
  content: "\eb44";
}

.remixicon-clockwise-line:before {
  content: "\eb45";
}

.remixicon-close-circle-fill:before {
  content: "\eb46";
}

.remixicon-close-circle-line:before {
  content: "\eb47";
}

.remixicon-close-fill:before {
  content: "\eb48";
}

.remixicon-close-line:before {
  content: "\eb49";
}

.remixicon-cloud-fill:before {
  content: "\eb4a";
}

.remixicon-cloud-line:before {
  content: "\eb4b";
}

.remixicon-cloud-windy-fill:before {
  content: "\eb4c";
}

.remixicon-cloud-windy-line:before {
  content: "\eb4d";
}

.remixicon-cloudy-2-fill:before {
  content: "\eb4e";
}

.remixicon-cloudy-2-line:before {
  content: "\eb4f";
}

.remixicon-cloudy-fill:before {
  content: "\eb50";
}

.remixicon-cloudy-line:before {
  content: "\eb51";
}

.remixicon-code-box-fill:before {
  content: "\eb52";
}

.remixicon-code-box-line:before {
  content: "\eb53";
}

.remixicon-code-fill:before {
  content: "\eb54";
}

.remixicon-code-line:before {
  content: "\eb55";
}

.remixicon-code-s-fill:before {
  content: "\eb56";
}

.remixicon-code-s-line:before {
  content: "\eb57";
}

.remixicon-code-s-slash-fill:before {
  content: "\eb58";
}

.remixicon-code-s-slash-line:before {
  content: "\eb59";
}

.remixicon-code-view:before {
  content: "\eb5a";
}

.remixicon-codepen-fill:before {
  content: "\eb5b";
}

.remixicon-codepen-line:before {
  content: "\eb5c";
}

.remixicon-command-fill:before {
  content: "\eb5d";
}

.remixicon-command-line:before {
  content: "\eb5e";
}

.remixicon-community-fill:before {
  content: "\eb5f";
}

.remixicon-community-line:before {
  content: "\eb60";
}

.remixicon-compass-2-fill:before {
  content: "\eb61";
}

.remixicon-compass-2-line:before {
  content: "\eb62";
}

.remixicon-compass-3-fill:before {
  content: "\eb63";
}

.remixicon-compass-3-line:before {
  content: "\eb64";
}

.remixicon-compass-4-fill:before {
  content: "\eb65";
}

.remixicon-compass-4-line:before {
  content: "\eb66";
}

.remixicon-compass-discover-fill:before {
  content: "\eb67";
}

.remixicon-compass-discover-line:before {
  content: "\eb68";
}

.remixicon-compass-fill:before {
  content: "\eb69";
}

.remixicon-compass-line:before {
  content: "\eb6a";
}

.remixicon-compasses-2-fill:before {
  content: "\eb6b";
}

.remixicon-compasses-2-line:before {
  content: "\eb6c";
}

.remixicon-compasses-fill:before {
  content: "\eb6d";
}

.remixicon-compasses-line:before {
  content: "\eb6e";
}

.remixicon-computer-fill:before {
  content: "\eb6f";
}

.remixicon-computer-line:before {
  content: "\eb70";
}

.remixicon-contacts-book-2-fill:before {
  content: "\eb71";
}

.remixicon-contacts-book-2-line:before {
  content: "\eb72";
}

.remixicon-contacts-book-fill:before {
  content: "\eb73";
}

.remixicon-contacts-book-line:before {
  content: "\eb74";
}

.remixicon-contacts-fill:before {
  content: "\eb75";
}

.remixicon-contacts-line:before {
  content: "\eb76";
}

.remixicon-contrast-2-fill:before {
  content: "\eb77";
}

.remixicon-contrast-2-line:before {
  content: "\eb78";
}

.remixicon-contrast-drop-2-fill:before {
  content: "\eb79";
}

.remixicon-contrast-drop-2-line:before {
  content: "\eb7a";
}

.remixicon-contrast-drop-fill:before {
  content: "\eb7b";
}

.remixicon-contrast-drop-line:before {
  content: "\eb7c";
}

.remixicon-contrast-fill:before {
  content: "\eb7d";
}

.remixicon-contrast-line:before {
  content: "\eb7e";
}

.remixicon-copper-coin-fill:before {
  content: "\eb7f";
}

.remixicon-copper-coin-line:before {
  content: "\eb80";
}

.remixicon-copper-diamond-fill:before {
  content: "\eb81";
}

.remixicon-copper-diamond-line:before {
  content: "\eb82";
}

.remixicon-coreos-fill:before {
  content: "\eb83";
}

.remixicon-coreos-line:before {
  content: "\eb84";
}

.remixicon-coupon-2-fill:before {
  content: "\eb85";
}

.remixicon-coupon-2-line:before {
  content: "\eb86";
}

.remixicon-coupon-3-fill:before {
  content: "\eb87";
}

.remixicon-coupon-3-line:before {
  content: "\eb88";
}

.remixicon-coupon-4-fill:before {
  content: "\eb89";
}

.remixicon-coupon-4-line:before {
  content: "\eb8a";
}

.remixicon-coupon-5-fill:before {
  content: "\eb8b";
}

.remixicon-coupon-5-line:before {
  content: "\eb8c";
}

.remixicon-coupon-fill:before {
  content: "\eb8d";
}

.remixicon-coupon-line:before {
  content: "\eb8e";
}

.remixicon-cpu-fill:before {
  content: "\eb8f";
}

.remixicon-cpu-line:before {
  content: "\eb90";
}

.remixicon-crop-2-fill:before {
  content: "\eb91";
}

.remixicon-crop-2-line:before {
  content: "\eb92";
}

.remixicon-crop-fill:before {
  content: "\eb93";
}

.remixicon-crop-line:before {
  content: "\eb94";
}

.remixicon-css3-fill:before {
  content: "\eb95";
}

.remixicon-css3-line:before {
  content: "\eb96";
}

.remixicon-cup-fill:before {
  content: "\eb97";
}

.remixicon-cup-line:before {
  content: "\eb98";
}

.remixicon-currency-fill:before {
  content: "\eb99";
}

.remixicon-currency-line:before {
  content: "\eb9a";
}

.remixicon-cursor-fill:before {
  content: "\eb9b";
}

.remixicon-cursor-line:before {
  content: "\eb9c";
}

.remixicon-customer-service-2-fill:before {
  content: "\eb9d";
}

.remixicon-customer-service-2-line:before {
  content: "\eb9e";
}

.remixicon-customer-service-fill:before {
  content: "\eb9f";
}

.remixicon-customer-service-line:before {
  content: "\eba0";
}

.remixicon-dashboard-fill:before {
  content: "\eba1";
}

.remixicon-dashboard-line:before {
  content: "\eba2";
}

.remixicon-database-2-fill:before {
  content: "\eba3";
}

.remixicon-database-2-line:before {
  content: "\eba4";
}

.remixicon-database-fill:before {
  content: "\eba5";
}

.remixicon-database-line:before {
  content: "\eba6";
}

.remixicon-delete-back-2-fill:before {
  content: "\eba7";
}

.remixicon-delete-back-2-line:before {
  content: "\eba8";
}

.remixicon-delete-back-fill:before {
  content: "\eba9";
}

.remixicon-delete-back-line:before {
  content: "\ebaa";
}

.remixicon-delete-bin-2-fill:before {
  content: "\ebab";
}

.remixicon-delete-bin-2-line:before {
  content: "\ebac";
}

.remixicon-delete-bin-3-fill:before {
  content: "\ebad";
}

.remixicon-delete-bin-3-line:before {
  content: "\ebae";
}

.remixicon-delete-bin-4-fill:before {
  content: "\ebaf";
}

.remixicon-delete-bin-4-line:before {
  content: "\ebb0";
}

.remixicon-delete-bin-5-fill:before {
  content: "\ebb1";
}

.remixicon-delete-bin-5-line:before {
  content: "\ebb2";
}

.remixicon-delete-bin-6-fill:before {
  content: "\ebb3";
}

.remixicon-delete-bin-6-line:before {
  content: "\ebb4";
}

.remixicon-delete-bin-7-fill:before {
  content: "\ebb5";
}

.remixicon-delete-bin-7-line:before {
  content: "\ebb6";
}

.remixicon-delete-bin-fill:before {
  content: "\ebb7";
}

.remixicon-delete-bin-line:before {
  content: "\ebb8";
}

.remixicon-device-fill:before {
  content: "\ebb9";
}

.remixicon-device-line:before {
  content: "\ebba";
}

.remixicon-dingding-fill:before {
  content: "\ebbb";
}

.remixicon-dingding-line:before {
  content: "\ebbc";
}

.remixicon-direction-fill:before {
  content: "\ebbd";
}

.remixicon-direction-line:before {
  content: "\ebbe";
}

.remixicon-disc-fill:before {
  content: "\ebbf";
}

.remixicon-disc-line:before {
  content: "\ebc0";
}

.remixicon-discord-fill:before {
  content: "\ebc1";
}

.remixicon-discord-line:before {
  content: "\ebc2";
}

.remixicon-discuss-fill:before {
  content: "\ebc3";
}

.remixicon-discuss-line:before {
  content: "\ebc4";
}

.remixicon-divide-fill:before {
  content: "\ebc5";
}

.remixicon-divide-line:before {
  content: "\ebc6";
}

.remixicon-door-lock-box-fill:before {
  content: "\ebc7";
}

.remixicon-door-lock-box-line:before {
  content: "\ebc8";
}

.remixicon-door-lock-fill:before {
  content: "\ebc9";
}

.remixicon-door-lock-line:before {
  content: "\ebca";
}

.remixicon-douban-fill:before {
  content: "\ebcb";
}

.remixicon-douban-line:before {
  content: "\ebcc";
}

.remixicon-double-quotes-l:before {
  content: "\ebcd";
}

.remixicon-double-quotes-r:before {
  content: "\ebce";
}

.remixicon-download-2-fill:before {
  content: "\ebcf";
}

.remixicon-download-2-line:before {
  content: "\ebd0";
}

.remixicon-download-cloud-fill:before {
  content: "\ebd1";
}

.remixicon-download-cloud-line:before {
  content: "\ebd2";
}

.remixicon-download-fill:before {
  content: "\ebd3";
}

.remixicon-download-line:before {
  content: "\ebd4";
}

.remixicon-drag-move-2-fill:before {
  content: "\ebd5";
}

.remixicon-drag-move-2-line:before {
  content: "\ebd6";
}

.remixicon-drag-move-fill:before {
  content: "\ebd7";
}

.remixicon-drag-move-line:before {
  content: "\ebd8";
}

.remixicon-dribbble-fill:before {
  content: "\ebd9";
}

.remixicon-dribbble-line:before {
  content: "\ebda";
}

.remixicon-drive-fill:before {
  content: "\ebdb";
}

.remixicon-drive-line:before {
  content: "\ebdc";
}

.remixicon-drizzle-fill:before {
  content: "\ebdd";
}

.remixicon-drizzle-line:before {
  content: "\ebde";
}

.remixicon-drop-fill:before {
  content: "\ebdf";
}

.remixicon-drop-line:before {
  content: "\ebe0";
}

.remixicon-dropbox-fill:before {
  content: "\ebe1";
}

.remixicon-dropbox-line:before {
  content: "\ebe2";
}

.remixicon-dv-fill:before {
  content: "\ebe3";
}

.remixicon-dv-line:before {
  content: "\ebe4";
}

.remixicon-dvd-fill:before {
  content: "\ebe5";
}

.remixicon-dvd-line:before {
  content: "\ebe6";
}

.remixicon-e-bike-2-fill:before {
  content: "\ebe7";
}

.remixicon-e-bike-2-line:before {
  content: "\ebe8";
}

.remixicon-e-bike-fill:before {
  content: "\ebe9";
}

.remixicon-e-bike-line:before {
  content: "\ebea";
}

.remixicon-earth-fill:before {
  content: "\ebeb";
}

.remixicon-earth-line:before {
  content: "\ebec";
}

.remixicon-edge-fill:before {
  content: "\ebed";
}

.remixicon-edge-line:before {
  content: "\ebee";
}

.remixicon-edit-2-fill:before {
  content: "\ebef";
}

.remixicon-edit-2-line:before {
  content: "\ebf0";
}

.remixicon-edit-box-fill:before {
  content: "\ebf1";
}

.remixicon-edit-box-line:before {
  content: "\ebf2";
}

.remixicon-edit-circle-fill:before {
  content: "\ebf3";
}

.remixicon-edit-circle-line:before {
  content: "\ebf4";
}

.remixicon-edit-fill:before {
  content: "\ebf5";
}

.remixicon-edit-line:before {
  content: "\ebf6";
}

.remixicon-eject-fill:before {
  content: "\ebf7";
}

.remixicon-eject-line:before {
  content: "\ebf8";
}

.remixicon-emotion-2-fill:before {
  content: "\ebf9";
}

.remixicon-emotion-2-line:before {
  content: "\ebfa";
}

.remixicon-emotion-fill:before {
  content: "\ebfb";
}

.remixicon-emotion-happy-fill:before {
  content: "\ebfc";
}

.remixicon-emotion-happy-line:before {
  content: "\ebfd";
}

.remixicon-emotion-line:before {
  content: "\ebfe";
}

.remixicon-emotion-normal-fill:before {
  content: "\ebff";
}

.remixicon-emotion-normal-line:before {
  content: "\ec00";
}

.remixicon-emotion-unhappy-fill:before {
  content: "\ec01";
}

.remixicon-emotion-unhappy-line:before {
  content: "\ec02";
}

.remixicon-equalizer-fill:before {
  content: "\ec03";
}

.remixicon-equalizer-line:before {
  content: "\ec04";
}

.remixicon-eraser-fill:before {
  content: "\ec05";
}

.remixicon-eraser-line:before {
  content: "\ec06";
}

.remixicon-error-warning-fill:before {
  content: "\ec07";
}

.remixicon-error-warning-line:before {
  content: "\ec08";
}

.remixicon-evernote-fill:before {
  content: "\ec09";
}

.remixicon-evernote-line:before {
  content: "\ec0a";
}

.remixicon-exchange-box-fill:before {
  content: "\ec0b";
}

.remixicon-exchange-box-line:before {
  content: "\ec0c";
}

.remixicon-exchange-cny-fill:before {
  content: "\ec0d";
}

.remixicon-exchange-cny-line:before {
  content: "\ec0e";
}

.remixicon-exchange-dollar-fill:before {
  content: "\ec0f";
}

.remixicon-exchange-dollar-line:before {
  content: "\ec10";
}

.remixicon-exchange-fill:before {
  content: "\ec11";
}

.remixicon-exchange-funds-fill:before {
  content: "\ec12";
}

.remixicon-exchange-funds-line:before {
  content: "\ec13";
}

.remixicon-exchange-line:before {
  content: "\ec14";
}

.remixicon-eye-close-fill:before {
  content: "\ec15";
}

.remixicon-eye-close-line:before {
  content: "\ec16";
}

.remixicon-eye-fill:before {
  content: "\ec17";
}

.remixicon-eye-line:before {
  content: "\ec18";
}

.remixicon-eye-off-fill:before {
  content: "\ec19";
}

.remixicon-eye-off-line:before {
  content: "\ec1a";
}

.remixicon-facebook-box-fill:before {
  content: "\ec1b";
}

.remixicon-facebook-box-line:before {
  content: "\ec1c";
}

.remixicon-facebook-circle-fill:before {
  content: "\ec1d";
}

.remixicon-facebook-circle-line:before {
  content: "\ec1e";
}

.remixicon-facebook-fill:before {
  content: "\ec1f";
}

.remixicon-facebook-line:before {
  content: "\ec20";
}

.remixicon-fahrenheit-fill:before {
  content: "\ec21";
}

.remixicon-fahrenheit-line:before {
  content: "\ec22";
}

.remixicon-feedback-fill:before {
  content: "\ec23";
}

.remixicon-feedback-line:before {
  content: "\ec24";
}

.remixicon-file-2-fill:before {
  content: "\ec25";
}

.remixicon-file-2-line:before {
  content: "\ec26";
}

.remixicon-file-3-fill:before {
  content: "\ec27";
}

.remixicon-file-3-line:before {
  content: "\ec28";
}

.remixicon-file-4-fill:before {
  content: "\ec29";
}

.remixicon-file-4-line:before {
  content: "\ec2a";
}

.remixicon-file-add-fill:before {
  content: "\ec2b";
}

.remixicon-file-add-line:before {
  content: "\ec2c";
}

.remixicon-file-chart-2-fill:before {
  content: "\ec2d";
}

.remixicon-file-chart-2-line:before {
  content: "\ec2e";
}

.remixicon-file-chart-fill:before {
  content: "\ec2f";
}

.remixicon-file-chart-line:before {
  content: "\ec30";
}

.remixicon-file-code-fill:before {
  content: "\ec31";
}

.remixicon-file-code-line:before {
  content: "\ec32";
}

.remixicon-file-copy-2-fill:before {
  content: "\ec33";
}

.remixicon-file-copy-2-line:before {
  content: "\ec34";
}

.remixicon-file-copy-fill:before {
  content: "\ec35";
}

.remixicon-file-copy-line:before {
  content: "\ec36";
}

.remixicon-file-damage-fill:before {
  content: "\ec37";
}

.remixicon-file-damage-line:before {
  content: "\ec38";
}

.remixicon-file-download-fill:before {
  content: "\ec39";
}

.remixicon-file-download-line:before {
  content: "\ec3a";
}

.remixicon-file-edit-fill:before {
  content: "\ec3b";
}

.remixicon-file-edit-line:before {
  content: "\ec3c";
}

.remixicon-file-excel-2-fill:before {
  content: "\ec3d";
}

.remixicon-file-excel-2-line:before {
  content: "\ec3e";
}

.remixicon-file-excel-fill:before {
  content: "\ec3f";
}

.remixicon-file-excel-line:before {
  content: "\ec40";
}

.remixicon-file-fill:before {
  content: "\ec41";
}

.remixicon-file-forbid-fill:before {
  content: "\ec42";
}

.remixicon-file-forbid-line:before {
  content: "\ec43";
}

.remixicon-file-info-fill:before {
  content: "\ec44";
}

.remixicon-file-info-line:before {
  content: "\ec45";
}

.remixicon-file-line:before {
  content: "\ec46";
}

.remixicon-file-list-2-fill:before {
  content: "\ec47";
}

.remixicon-file-list-2-line:before {
  content: "\ec48";
}

.remixicon-file-list-3-fill:before {
  content: "\ec49";
}

.remixicon-file-list-3-line:before {
  content: "\ec4a";
}

.remixicon-file-list-fill:before {
  content: "\ec4b";
}

.remixicon-file-list-line:before {
  content: "\ec4c";
}

.remixicon-file-lock-fill:before {
  content: "\ec4d";
}

.remixicon-file-lock-line:before {
  content: "\ec4e";
}

.remixicon-file-mark-fill:before {
  content: "\ec4f";
}

.remixicon-file-mark-line:before {
  content: "\ec50";
}

.remixicon-file-music-fill:before {
  content: "\ec51";
}

.remixicon-file-music-line:before {
  content: "\ec52";
}

.remixicon-file-paper-fill:before {
  content: "\ec53";
}

.remixicon-file-paper-line:before {
  content: "\ec54";
}

.remixicon-file-pdf-fill:before {
  content: "\ec55";
}

.remixicon-file-pdf-line:before {
  content: "\ec56";
}

.remixicon-file-ppt-2-fill:before {
  content: "\ec57";
}

.remixicon-file-ppt-2-line:before {
  content: "\ec58";
}

.remixicon-file-ppt-fill:before {
  content: "\ec59";
}

.remixicon-file-ppt-line:before {
  content: "\ec5a";
}

.remixicon-file-reduce-fill:before {
  content: "\ec5b";
}

.remixicon-file-reduce-line:before {
  content: "\ec5c";
}

.remixicon-file-search-fill:before {
  content: "\ec5d";
}

.remixicon-file-search-line:before {
  content: "\ec5e";
}

.remixicon-file-settings-fill:before {
  content: "\ec5f";
}

.remixicon-file-settings-line:before {
  content: "\ec60";
}

.remixicon-file-shield-2-fill:before {
  content: "\ec61";
}

.remixicon-file-shield-2-line:before {
  content: "\ec62";
}

.remixicon-file-shield-fill:before {
  content: "\ec63";
}

.remixicon-file-shield-line:before {
  content: "\ec64";
}

.remixicon-file-shred-fill:before {
  content: "\ec65";
}

.remixicon-file-shred-line:before {
  content: "\ec66";
}

.remixicon-file-text-fill:before {
  content: "\ec67";
}

.remixicon-file-text-line:before {
  content: "\ec68";
}

.remixicon-file-transfer-fill:before {
  content: "\ec69";
}

.remixicon-file-transfer-line:before {
  content: "\ec6a";
}

.remixicon-file-unknow-fill:before {
  content: "\ec6b";
}

.remixicon-file-unknow-line:before {
  content: "\ec6c";
}

.remixicon-file-upload-fill:before {
  content: "\ec6d";
}

.remixicon-file-upload-line:before {
  content: "\ec6e";
}

.remixicon-file-user-fill:before {
  content: "\ec6f";
}

.remixicon-file-user-line:before {
  content: "\ec70";
}

.remixicon-file-warning-fill:before {
  content: "\ec71";
}

.remixicon-file-warning-line:before {
  content: "\ec72";
}

.remixicon-file-word-2-fill:before {
  content: "\ec73";
}

.remixicon-file-word-2-line:before {
  content: "\ec74";
}

.remixicon-file-word-fill:before {
  content: "\ec75";
}

.remixicon-file-word-line:before {
  content: "\ec76";
}

.remixicon-file-zip-fill:before {
  content: "\ec77";
}

.remixicon-file-zip-line:before {
  content: "\ec78";
}

.remixicon-film-fill:before {
  content: "\ec79";
}

.remixicon-film-line:before {
  content: "\ec7a";
}

.remixicon-filter-2-fill:before {
  content: "\ec7b";
}

.remixicon-filter-2-line:before {
  content: "\ec7c";
}

.remixicon-filter-3-fill:before {
  content: "\ec7d";
}

.remixicon-filter-3-line:before {
  content: "\ec7e";
}

.remixicon-filter-fill:before {
  content: "\ec7f";
}

.remixicon-filter-line:before {
  content: "\ec80";
}

.remixicon-find-replace-fill:before {
  content: "\ec81";
}

.remixicon-find-replace-line:before {
  content: "\ec82";
}

.remixicon-fire-fill:before {
  content: "\ec83";
}

.remixicon-fire-line:before {
  content: "\ec84";
}

.remixicon-firefox-fill:before {
  content: "\ec85";
}

.remixicon-firefox-line:before {
  content: "\ec86";
}

.remixicon-flag-2-fill:before {
  content: "\ec87";
}

.remixicon-flag-2-line:before {
  content: "\ec88";
}

.remixicon-flag-fill:before {
  content: "\ec89";
}

.remixicon-flag-line:before {
  content: "\ec8a";
}

.remixicon-flashlight-fill:before {
  content: "\ec8b";
}

.remixicon-flashlight-line:before {
  content: "\ec8c";
}

.remixicon-flight-land-fill:before {
  content: "\ec8d";
}

.remixicon-flight-land-line:before {
  content: "\ec8e";
}

.remixicon-flight-takeoff-fill:before {
  content: "\ec8f";
}

.remixicon-flight-takeoff-line:before {
  content: "\ec90";
}

.remixicon-focus-2-fill:before {
  content: "\ec91";
}

.remixicon-focus-2-line:before {
  content: "\ec92";
}

.remixicon-focus-fill:before {
  content: "\ec93";
}

.remixicon-focus-line:before {
  content: "\ec94";
}

.remixicon-foggy-fill:before {
  content: "\ec95";
}

.remixicon-foggy-line:before {
  content: "\ec96";
}

.remixicon-folder-2-fill:before {
  content: "\ec97";
}

.remixicon-folder-2-line:before {
  content: "\ec98";
}

.remixicon-folder-3-fill:before {
  content: "\ec99";
}

.remixicon-folder-3-line:before {
  content: "\ec9a";
}

.remixicon-folder-4-fill:before {
  content: "\ec9b";
}

.remixicon-folder-4-line:before {
  content: "\ec9c";
}

.remixicon-folder-5-fill:before {
  content: "\ec9d";
}

.remixicon-folder-5-line:before {
  content: "\ec9e";
}

.remixicon-folder-add-fill:before {
  content: "\ec9f";
}

.remixicon-folder-add-line:before {
  content: "\eca0";
}

.remixicon-folder-chart-2-fill:before {
  content: "\eca1";
}

.remixicon-folder-chart-2-line:before {
  content: "\eca2";
}

.remixicon-folder-chart-fill:before {
  content: "\eca3";
}

.remixicon-folder-chart-line:before {
  content: "\eca4";
}

.remixicon-folder-download-fill:before {
  content: "\eca5";
}

.remixicon-folder-download-line:before {
  content: "\eca6";
}

.remixicon-folder-fill:before {
  content: "\eca7";
}

.remixicon-folder-forbid-fill:before {
  content: "\eca8";
}

.remixicon-folder-forbid-line:before {
  content: "\eca9";
}

.remixicon-folder-info-fill:before {
  content: "\ecaa";
}

.remixicon-folder-info-line:before {
  content: "\ecab";
}

.remixicon-folder-line:before {
  content: "\ecac";
}

.remixicon-folder-lock-fill:before {
  content: "\ecad";
}

.remixicon-folder-lock-line:before {
  content: "\ecae";
}

.remixicon-folder-music-fill:before {
  content: "\ecaf";
}

.remixicon-folder-music-line:before {
  content: "\ecb0";
}

.remixicon-folder-received-fill:before {
  content: "\ecb1";
}

.remixicon-folder-received-line:before {
  content: "\ecb2";
}

.remixicon-folder-reduce-fill:before {
  content: "\ecb3";
}

.remixicon-folder-reduce-line:before {
  content: "\ecb4";
}

.remixicon-folder-settings-fill:before {
  content: "\ecb5";
}

.remixicon-folder-settings-line:before {
  content: "\ecb6";
}

.remixicon-folder-shared-fill:before {
  content: "\ecb7";
}

.remixicon-folder-shared-line:before {
  content: "\ecb8";
}

.remixicon-folder-shield-2-fill:before {
  content: "\ecb9";
}

.remixicon-folder-shield-2-line:before {
  content: "\ecba";
}

.remixicon-folder-shield-fill:before {
  content: "\ecbb";
}

.remixicon-folder-shield-line:before {
  content: "\ecbc";
}

.remixicon-folder-transfer-fill:before {
  content: "\ecbd";
}

.remixicon-folder-transfer-line:before {
  content: "\ecbe";
}

.remixicon-folder-unknow-fill:before {
  content: "\ecbf";
}

.remixicon-folder-unknow-line:before {
  content: "\ecc0";
}

.remixicon-folder-upload-fill:before {
  content: "\ecc1";
}

.remixicon-folder-upload-line:before {
  content: "\ecc2";
}

.remixicon-folder-user-fill:before {
  content: "\ecc3";
}

.remixicon-folder-user-line:before {
  content: "\ecc4";
}

.remixicon-folder-warning-fill:before {
  content: "\ecc5";
}

.remixicon-folder-warning-line:before {
  content: "\ecc6";
}

.remixicon-folders-fill:before {
  content: "\ecc7";
}

.remixicon-folders-line:before {
  content: "\ecc8";
}

.remixicon-font-color:before {
  content: "\ecc9";
}

.remixicon-font-size-2:before {
  content: "\ecca";
}

.remixicon-font-size:before {
  content: "\eccb";
}

.remixicon-footprint-fill:before {
  content: "\eccc";
}

.remixicon-footprint-line:before {
  content: "\eccd";
}

.remixicon-forbid-2-fill:before {
  content: "\ecce";
}

.remixicon-forbid-2-line:before {
  content: "\eccf";
}

.remixicon-forbid-fill:before {
  content: "\ecd0";
}

.remixicon-forbid-line:before {
  content: "\ecd1";
}

.remixicon-format-clear:before {
  content: "\ecd2";
}

.remixicon-fullscreen-exit-fill:before {
  content: "\ecd3";
}

.remixicon-fullscreen-exit-line:before {
  content: "\ecd4";
}

.remixicon-fullscreen-fill:before {
  content: "\ecd5";
}

.remixicon-fullscreen-line:before {
  content: "\ecd6";
}

.remixicon-function-fill:before {
  content: "\ecd7";
}

.remixicon-function-line:before {
  content: "\ecd8";
}

.remixicon-functions:before {
  content: "\ecd9";
}

.remixicon-funds-box-fill:before {
  content: "\ecda";
}

.remixicon-funds-box-line:before {
  content: "\ecdb";
}

.remixicon-funds-fill:before {
  content: "\ecdc";
}

.remixicon-funds-line:before {
  content: "\ecdd";
}

.remixicon-gallery-fill:before {
  content: "\ecde";
}

.remixicon-gallery-line:before {
  content: "\ecdf";
}

.remixicon-gas-station-fill:before {
  content: "\ece0";
}

.remixicon-gas-station-line:before {
  content: "\ece1";
}

.remixicon-genderless-fill:before {
  content: "\ece2";
}

.remixicon-genderless-line:before {
  content: "\ece3";
}

.remixicon-git-branch-fill:before {
  content: "\ece4";
}

.remixicon-git-branch-line:before {
  content: "\ece5";
}

.remixicon-git-commit-fill:before {
  content: "\ece6";
}

.remixicon-git-commit-line:before {
  content: "\ece7";
}

.remixicon-git-merge-fill:before {
  content: "\ece8";
}

.remixicon-git-merge-line:before {
  content: "\ece9";
}

.remixicon-git-pull-request-fill:before {
  content: "\ecea";
}

.remixicon-git-pull-request-line:before {
  content: "\eceb";
}

.remixicon-git-repository-commits-fill:before {
  content: "\ecec";
}

.remixicon-git-repository-commits-line:before {
  content: "\eced";
}

.remixicon-git-repository-fill:before {
  content: "\ecee";
}

.remixicon-git-repository-line:before {
  content: "\ecef";
}

.remixicon-git-repository-private-fill:before {
  content: "\ecf0";
}

.remixicon-git-repository-private-line:before {
  content: "\ecf1";
}

.remixicon-github-fill:before {
  content: "\ecf2";
}

.remixicon-github-line:before {
  content: "\ecf3";
}

.remixicon-gitlab-fill:before {
  content: "\ecf4";
}

.remixicon-gitlab-line:before {
  content: "\ecf5";
}

.remixicon-global-fill:before {
  content: "\ecf6";
}

.remixicon-global-line:before {
  content: "\ecf7";
}

.remixicon-globe-fill:before {
  content: "\ecf8";
}

.remixicon-globe-line:before {
  content: "\ecf9";
}

.remixicon-goblet-fill:before {
  content: "\ecfa";
}

.remixicon-goblet-line:before {
  content: "\ecfb";
}

.remixicon-google-fill:before {
  content: "\ecfc";
}

.remixicon-google-line:before {
  content: "\ecfd";
}

.remixicon-government-fill:before {
  content: "\ecfe";
}

.remixicon-government-line:before {
  content: "\ecff";
}

.remixicon-gps-fill:before {
  content: "\ed00";
}

.remixicon-gps-line:before {
  content: "\ed01";
}

.remixicon-gradienter-fill:before {
  content: "\ed02";
}

.remixicon-gradienter-line:before {
  content: "\ed03";
}

.remixicon-grid-fill:before {
  content: "\ed04";
}

.remixicon-grid-line:before {
  content: "\ed05";
}

.remixicon-group-2-fill:before {
  content: "\ed06";
}

.remixicon-group-2-line:before {
  content: "\ed07";
}

.remixicon-group-fill:before {
  content: "\ed08";
}

.remixicon-group-line:before {
  content: "\ed09";
}

.remixicon-guide-fill:before {
  content: "\ed0a";
}

.remixicon-guide-line:before {
  content: "\ed0b";
}

.remixicon-hail-fill:before {
  content: "\ed0c";
}

.remixicon-hail-line:before {
  content: "\ed0d";
}

.remixicon-hard-drive-2-fill:before {
  content: "\ed0e";
}

.remixicon-hard-drive-2-line:before {
  content: "\ed0f";
}

.remixicon-hard-drive-fill:before {
  content: "\ed10";
}

.remixicon-hard-drive-line:before {
  content: "\ed11";
}

.remixicon-hashtag:before {
  content: "\ed12";
}

.remixicon-haze-fill:before {
  content: "\ed13";
}

.remixicon-haze-line:before {
  content: "\ed14";
}

.remixicon-hd-fill:before {
  content: "\ed15";
}

.remixicon-hd-line:before {
  content: "\ed16";
}

.remixicon-heading:before {
  content: "\ed17";
}

.remixicon-headphone-fill:before {
  content: "\ed18";
}

.remixicon-headphone-line:before {
  content: "\ed19";
}

.remixicon-heart-2-fill:before {
  content: "\ed1a";
}

.remixicon-heart-2-line:before {
  content: "\ed1b";
}

.remixicon-heart-fill:before {
  content: "\ed1c";
}

.remixicon-heart-line:before {
  content: "\ed1d";
}

.remixicon-heavy-showers-fill:before {
  content: "\ed1e";
}

.remixicon-heavy-showers-line:before {
  content: "\ed1f";
}

.remixicon-home-2-fill:before {
  content: "\ed20";
}

.remixicon-home-2-line:before {
  content: "\ed21";
}

.remixicon-home-3-fill:before {
  content: "\ed22";
}

.remixicon-home-3-line:before {
  content: "\ed23";
}

.remixicon-home-4-fill:before {
  content: "\ed24";
}

.remixicon-home-4-line:before {
  content: "\ed25";
}

.remixicon-home-5-fill:before {
  content: "\ed26";
}

.remixicon-home-5-line:before {
  content: "\ed27";
}

.remixicon-home-6-fill:before {
  content: "\ed28";
}

.remixicon-home-6-line:before {
  content: "\ed29";
}

.remixicon-home-7-fill:before {
  content: "\ed2a";
}

.remixicon-home-7-line:before {
  content: "\ed2b";
}

.remixicon-home-8-fill:before {
  content: "\ed2c";
}

.remixicon-home-8-line:before {
  content: "\ed2d";
}

.remixicon-home-fill:before {
  content: "\ed2e";
}

.remixicon-home-gear-fill:before {
  content: "\ed2f";
}

.remixicon-home-gear-line:before {
  content: "\ed30";
}

.remixicon-home-heart-fill:before {
  content: "\ed31";
}

.remixicon-home-heart-line:before {
  content: "\ed32";
}

.remixicon-home-line:before {
  content: "\ed33";
}

.remixicon-home-smile-2-fill:before {
  content: "\ed34";
}

.remixicon-home-smile-2-line:before {
  content: "\ed35";
}

.remixicon-home-smile-fill:before {
  content: "\ed36";
}

.remixicon-home-smile-line:before {
  content: "\ed37";
}

.remixicon-home-wifi-fill:before {
  content: "\ed38";
}

.remixicon-home-wifi-line:before {
  content: "\ed39";
}

.remixicon-honour-fill:before {
  content: "\ed3a";
}

.remixicon-honour-line:before {
  content: "\ed3b";
}

.remixicon-hospital-fill:before {
  content: "\ed3c";
}

.remixicon-hospital-line:before {
  content: "\ed3d";
}

.remixicon-hotel-bed-fill:before {
  content: "\ed3e";
}

.remixicon-hotel-bed-line:before {
  content: "\ed3f";
}

.remixicon-hotel-fill:before {
  content: "\ed40";
}

.remixicon-hotel-line:before {
  content: "\ed41";
}

.remixicon-hq-fill:before {
  content: "\ed42";
}

.remixicon-hq-line:before {
  content: "\ed43";
}

.remixicon-html5-fill:before {
  content: "\ed44";
}

.remixicon-html5-line:before {
  content: "\ed45";
}

.remixicon-ie-fill:before {
  content: "\ed46";
}

.remixicon-ie-line:before {
  content: "\ed47";
}

.remixicon-image-2-fill:before {
  content: "\ed48";
}

.remixicon-image-2-line:before {
  content: "\ed49";
}

.remixicon-image-fill:before {
  content: "\ed4a";
}

.remixicon-image-line:before {
  content: "\ed4b";
}

.remixicon-inbox-archive-fill:before {
  content: "\ed4c";
}

.remixicon-inbox-archive-line:before {
  content: "\ed4d";
}

.remixicon-inbox-fill:before {
  content: "\ed4e";
}

.remixicon-inbox-line:before {
  content: "\ed4f";
}

.remixicon-increase-decrease-fill:before {
  content: "\ed50";
}

.remixicon-increase-decrease-line:before {
  content: "\ed51";
}

.remixicon-indent-decrease:before {
  content: "\ed52";
}

.remixicon-indent-increase:before {
  content: "\ed53";
}

.remixicon-indeterminate-circle-fill:before {
  content: "\ed54";
}

.remixicon-indeterminate-circle-line:before {
  content: "\ed55";
}

.remixicon-information-fill:before {
  content: "\ed56";
}

.remixicon-information-line:before {
  content: "\ed57";
}

.remixicon-input-method-fill:before {
  content: "\ed58";
}

.remixicon-input-method-line:before {
  content: "\ed59";
}

.remixicon-instagram-fill:before {
  content: "\ed5a";
}

.remixicon-instagram-line:before {
  content: "\ed5b";
}

.remixicon-invision-fill:before {
  content: "\ed5c";
}

.remixicon-invision-line:before {
  content: "\ed5d";
}

.remixicon-italic:before {
  content: "\ed5e";
}

.remixicon-kakao-talk-fill:before {
  content: "\ed5f";
}

.remixicon-kakao-talk-line:before {
  content: "\ed60";
}

.remixicon-key-2-fill:before {
  content: "\ed61";
}

.remixicon-key-2-line:before {
  content: "\ed62";
}

.remixicon-key-fill:before {
  content: "\ed63";
}

.remixicon-key-line:before {
  content: "\ed64";
}

.remixicon-keyboard-box-fill:before {
  content: "\ed65";
}

.remixicon-keyboard-box-line:before {
  content: "\ed66";
}

.remixicon-keyboard-fill:before {
  content: "\ed67";
}

.remixicon-keyboard-line:before {
  content: "\ed68";
}

.remixicon-keynote-fill:before {
  content: "\ed69";
}

.remixicon-keynote-line:before {
  content: "\ed6a";
}

.remixicon-landscape-fill:before {
  content: "\ed6b";
}

.remixicon-landscape-line:before {
  content: "\ed6c";
}

.remixicon-layout-column-fill:before {
  content: "\ed6d";
}

.remixicon-layout-column-line:before {
  content: "\ed6e";
}

.remixicon-layout-fill:before {
  content: "\ed6f";
}

.remixicon-layout-line:before {
  content: "\ed70";
}

.remixicon-layout-row-fill:before {
  content: "\ed71";
}

.remixicon-layout-row-line:before {
  content: "\ed72";
}

.remixicon-lightbulb-fill:before {
  content: "\ed73";
}

.remixicon-lightbulb-flash-fill:before {
  content: "\ed74";
}

.remixicon-lightbulb-flash-line:before {
  content: "\ed75";
}

.remixicon-lightbulb-line:before {
  content: "\ed76";
}

.remixicon-line-fill:before {
  content: "\ed77";
}

.remixicon-line-height:before {
  content: "\ed78";
}

.remixicon-line-line:before {
  content: "\ed79";
}

.remixicon-link-m:before {
  content: "\ed7a";
}

.remixicon-link-unlink-m:before {
  content: "\ed7b";
}

.remixicon-link-unlink:before {
  content: "\ed7c";
}

.remixicon-link:before {
  content: "\ed7d";
}

.remixicon-linkedin-box-fill:before {
  content: "\ed7e";
}

.remixicon-linkedin-box-line:before {
  content: "\ed7f";
}

.remixicon-linkedin-fill:before {
  content: "\ed80";
}

.remixicon-linkedin-line:before {
  content: "\ed81";
}

.remixicon-links-fill:before {
  content: "\ed82";
}

.remixicon-links-line:before {
  content: "\ed83";
}

.remixicon-list-check-2:before {
  content: "\ed84";
}

.remixicon-list-check:before {
  content: "\ed85";
}

.remixicon-list-ordered:before {
  content: "\ed86";
}

.remixicon-list-settings-fill:before {
  content: "\ed87";
}

.remixicon-list-settings-line:before {
  content: "\ed88";
}

.remixicon-list-unordered:before {
  content: "\ed89";
}

.remixicon-loader-2-fill:before {
  content: "\ed8a";
}

.remixicon-loader-2-line:before {
  content: "\ed8b";
}

.remixicon-loader-3-fill:before {
  content: "\ed8c";
}

.remixicon-loader-3-line:before {
  content: "\ed8d";
}

.remixicon-loader-4-fill:before {
  content: "\ed8e";
}

.remixicon-loader-4-line:before {
  content: "\ed8f";
}

.remixicon-loader-5-fill:before {
  content: "\ed90";
}

.remixicon-loader-5-line:before {
  content: "\ed91";
}

.remixicon-loader-fill:before {
  content: "\ed92";
}

.remixicon-loader-line:before {
  content: "\ed93";
}

.remixicon-lock-2-fill:before {
  content: "\ed94";
}

.remixicon-lock-2-line:before {
  content: "\ed95";
}

.remixicon-lock-fill:before {
  content: "\ed96";
}

.remixicon-lock-line:before {
  content: "\ed97";
}

.remixicon-lock-password-fill:before {
  content: "\ed98";
}

.remixicon-lock-password-line:before {
  content: "\ed99";
}

.remixicon-lock-unlock-fill:before {
  content: "\ed9a";
}

.remixicon-lock-unlock-line:before {
  content: "\ed9b";
}

.remixicon-login-box-fill:before {
  content: "\ed9c";
}

.remixicon-login-box-line:before {
  content: "\ed9d";
}

.remixicon-login-circle-fill:before {
  content: "\ed9e";
}

.remixicon-login-circle-line:before {
  content: "\ed9f";
}

.remixicon-logout-box-fill:before {
  content: "\eda0";
}

.remixicon-logout-box-line:before {
  content: "\eda1";
}

.remixicon-logout-circle-fill:before {
  content: "\eda2";
}

.remixicon-logout-circle-line:before {
  content: "\eda3";
}

.remixicon-mac-fill:before {
  content: "\eda4";
}

.remixicon-mac-line:before {
  content: "\eda5";
}

.remixicon-macbook-fill:before {
  content: "\eda6";
}

.remixicon-macbook-line:before {
  content: "\eda7";
}

.remixicon-magic-fill:before {
  content: "\eda8";
}

.remixicon-magic-line:before {
  content: "\eda9";
}

.remixicon-mail-add-fill:before {
  content: "\edaa";
}

.remixicon-mail-add-line:before {
  content: "\edab";
}

.remixicon-mail-check-fill:before {
  content: "\edac";
}

.remixicon-mail-check-line:before {
  content: "\edad";
}

.remixicon-mail-close-fill:before {
  content: "\edae";
}

.remixicon-mail-close-line:before {
  content: "\edaf";
}

.remixicon-mail-download-fill:before {
  content: "\edb0";
}

.remixicon-mail-download-line:before {
  content: "\edb1";
}

.remixicon-mail-fill:before {
  content: "\edb2";
}

.remixicon-mail-forbid-fill:before {
  content: "\edb3";
}

.remixicon-mail-forbid-line:before {
  content: "\edb4";
}

.remixicon-mail-line:before {
  content: "\edb5";
}

.remixicon-mail-lock-fill:before {
  content: "\edb6";
}

.remixicon-mail-lock-line:before {
  content: "\edb7";
}

.remixicon-mail-open-fill:before {
  content: "\edb8";
}

.remixicon-mail-open-line:before {
  content: "\edb9";
}

.remixicon-mail-send-fill:before {
  content: "\edba";
}

.remixicon-mail-send-line:before {
  content: "\edbb";
}

.remixicon-mail-settings-fill:before {
  content: "\edbc";
}

.remixicon-mail-settings-line:before {
  content: "\edbd";
}

.remixicon-mail-star-fill:before {
  content: "\edbe";
}

.remixicon-mail-star-line:before {
  content: "\edbf";
}

.remixicon-mail-unread-fill:before {
  content: "\edc0";
}

.remixicon-mail-unread-line:before {
  content: "\edc1";
}

.remixicon-map-2-fill:before {
  content: "\edc2";
}

.remixicon-map-2-line:before {
  content: "\edc3";
}

.remixicon-map-fill:before {
  content: "\edc4";
}

.remixicon-map-line:before {
  content: "\edc5";
}

.remixicon-map-pin-2-fill:before {
  content: "\edc6";
}

.remixicon-map-pin-2-line:before {
  content: "\edc7";
}

.remixicon-map-pin-3-fill:before {
  content: "\edc8";
}

.remixicon-map-pin-3-line:before {
  content: "\edc9";
}

.remixicon-map-pin-4-fill:before {
  content: "\edca";
}

.remixicon-map-pin-4-line:before {
  content: "\edcb";
}

.remixicon-map-pin-5-fill:before {
  content: "\edcc";
}

.remixicon-map-pin-5-line:before {
  content: "\edcd";
}

.remixicon-map-pin-add-fill:before {
  content: "\edce";
}

.remixicon-map-pin-add-line:before {
  content: "\edcf";
}

.remixicon-map-pin-fill:before {
  content: "\edd0";
}

.remixicon-map-pin-line:before {
  content: "\edd1";
}

.remixicon-map-pin-range-fill:before {
  content: "\edd2";
}

.remixicon-map-pin-range-line:before {
  content: "\edd3";
}

.remixicon-map-pin-time-fill:before {
  content: "\edd4";
}

.remixicon-map-pin-time-line:before {
  content: "\edd5";
}

.remixicon-map-pin-user-fill:before {
  content: "\edd6";
}

.remixicon-map-pin-user-line:before {
  content: "\edd7";
}

.remixicon-mark-pen-fill:before {
  content: "\edd8";
}

.remixicon-mark-pen-line:before {
  content: "\edd9";
}

.remixicon-markdown-fill:before {
  content: "\edda";
}

.remixicon-markdown-line:before {
  content: "\eddb";
}

.remixicon-markup-fill:before {
  content: "\eddc";
}

.remixicon-markup-line:before {
  content: "\eddd";
}

.remixicon-mastercard-fill:before {
  content: "\edde";
}

.remixicon-mastercard-line:before {
  content: "\eddf";
}

.remixicon-mastodon-fill:before {
  content: "\ede0";
}

.remixicon-mastodon-line:before {
  content: "\ede1";
}

.remixicon-medium-fill:before {
  content: "\ede2";
}

.remixicon-medium-line:before {
  content: "\ede3";
}

.remixicon-men-fill:before {
  content: "\ede4";
}

.remixicon-men-line:before {
  content: "\ede5";
}

.remixicon-menu-2-fill:before {
  content: "\ede6";
}

.remixicon-menu-2-line:before {
  content: "\ede7";
}

.remixicon-menu-3-fill:before {
  content: "\ede8";
}

.remixicon-menu-3-line:before {
  content: "\ede9";
}

.remixicon-menu-fill:before {
  content: "\edea";
}

.remixicon-menu-line:before {
  content: "\edeb";
}

.remixicon-message-2-fill:before {
  content: "\edec";
}

.remixicon-message-2-line:before {
  content: "\eded";
}

.remixicon-message-3-fill:before {
  content: "\edee";
}

.remixicon-message-3-line:before {
  content: "\edef";
}

.remixicon-message-fill:before {
  content: "\edf0";
}

.remixicon-message-line:before {
  content: "\edf1";
}

.remixicon-messenger-fill:before {
  content: "\edf2";
}

.remixicon-messenger-line:before {
  content: "\edf3";
}

.remixicon-mic-2-fill:before {
  content: "\edf4";
}

.remixicon-mic-2-line:before {
  content: "\edf5";
}

.remixicon-mic-fill:before {
  content: "\edf6";
}

.remixicon-mic-line:before {
  content: "\edf7";
}

.remixicon-mic-off-fill:before {
  content: "\edf8";
}

.remixicon-mic-off-line:before {
  content: "\edf9";
}

.remixicon-mini-program-fill:before {
  content: "\edfa";
}

.remixicon-mini-program-line:before {
  content: "\edfb";
}

.remixicon-mist-fill:before {
  content: "\edfc";
}

.remixicon-mist-line:before {
  content: "\edfd";
}

.remixicon-money-cny-box-fill:before {
  content: "\edfe";
}

.remixicon-money-cny-box-line:before {
  content: "\edff";
}

.remixicon-money-cny-circle-fill:before {
  content: "\ee00";
}

.remixicon-money-cny-circle-line:before {
  content: "\ee01";
}

.remixicon-money-dollar-box-fill:before {
  content: "\ee02";
}

.remixicon-money-dollar-box-line:before {
  content: "\ee03";
}

.remixicon-money-dollar-circle-fill:before {
  content: "\ee04";
}

.remixicon-money-dollar-circle-line:before {
  content: "\ee05";
}

.remixicon-money-euro-box-fill:before {
  content: "\ee06";
}

.remixicon-money-euro-box-line:before {
  content: "\ee07";
}

.remixicon-money-euro-circle-fill:before {
  content: "\ee08";
}

.remixicon-money-euro-circle-line:before {
  content: "\ee09";
}

.remixicon-money-pound-box-fill:before {
  content: "\ee0a";
}

.remixicon-money-pound-box-line:before {
  content: "\ee0b";
}

.remixicon-money-pound-circle-fill:before {
  content: "\ee0c";
}

.remixicon-money-pound-circle-line:before {
  content: "\ee0d";
}

.remixicon-moon-clear-fill:before {
  content: "\ee0e";
}

.remixicon-moon-clear-line:before {
  content: "\ee0f";
}

.remixicon-moon-cloudy-fill:before {
  content: "\ee10";
}

.remixicon-moon-cloudy-line:before {
  content: "\ee11";
}

.remixicon-moon-fill:before {
  content: "\ee12";
}

.remixicon-moon-foggy-fill:before {
  content: "\ee13";
}

.remixicon-moon-foggy-line:before {
  content: "\ee14";
}

.remixicon-moon-line:before {
  content: "\ee15";
}

.remixicon-more-2-fill:before {
  content: "\ee16";
}

.remixicon-more-2-line:before {
  content: "\ee17";
}

.remixicon-more-fill:before {
  content: "\ee18";
}

.remixicon-more-line:before {
  content: "\ee19";
}

.remixicon-motorbike-fill:before {
  content: "\ee1a";
}

.remixicon-motorbike-line:before {
  content: "\ee1b";
}

.remixicon-mouse-fill:before {
  content: "\ee1c";
}

.remixicon-mouse-line:before {
  content: "\ee1d";
}

.remixicon-movie-2-fill:before {
  content: "\ee1e";
}

.remixicon-movie-2-line:before {
  content: "\ee1f";
}

.remixicon-movie-fill:before {
  content: "\ee20";
}

.remixicon-movie-line:before {
  content: "\ee21";
}

.remixicon-music-2-fill:before {
  content: "\ee22";
}

.remixicon-music-2-line:before {
  content: "\ee23";
}

.remixicon-music-fill:before {
  content: "\ee24";
}

.remixicon-music-line:before {
  content: "\ee25";
}

.remixicon-mv-fill:before {
  content: "\ee26";
}

.remixicon-mv-line:before {
  content: "\ee27";
}

.remixicon-navigation-fill:before {
  content: "\ee28";
}

.remixicon-navigation-line:before {
  content: "\ee29";
}

.remixicon-netease-cloud-music-fill:before {
  content: "\ee2a";
}

.remixicon-netease-cloud-music-line:before {
  content: "\ee2b";
}

.remixicon-netflix-fill:before {
  content: "\ee2c";
}

.remixicon-netflix-line:before {
  content: "\ee2d";
}

.remixicon-newspaper-fill:before {
  content: "\ee2e";
}

.remixicon-newspaper-line:before {
  content: "\ee2f";
}

.remixicon-notification-2-fill:before {
  content: "\ee30";
}

.remixicon-notification-2-line:before {
  content: "\ee31";
}

.remixicon-notification-3-fill:before {
  content: "\ee32";
}

.remixicon-notification-3-line:before {
  content: "\ee33";
}

.remixicon-notification-4-fill:before {
  content: "\ee34";
}

.remixicon-notification-4-line:before {
  content: "\ee35";
}

.remixicon-notification-badge-fill:before {
  content: "\ee36";
}

.remixicon-notification-badge-line:before {
  content: "\ee37";
}

.remixicon-notification-fill:before {
  content: "\ee38";
}

.remixicon-notification-line:before {
  content: "\ee39";
}

.remixicon-notification-off-fill:before {
  content: "\ee3a";
}

.remixicon-notification-off-line:before {
  content: "\ee3b";
}

.remixicon-numbers-fill:before {
  content: "\ee3c";
}

.remixicon-numbers-line:before {
  content: "\ee3d";
}

.remixicon-oil-fill:before {
  content: "\ee3e";
}

.remixicon-oil-line:before {
  content: "\ee3f";
}

.remixicon-omega:before {
  content: "\ee40";
}

.remixicon-open-arm-fill:before {
  content: "\ee41";
}

.remixicon-open-arm-line:before {
  content: "\ee42";
}

.remixicon-opera-fill:before {
  content: "\ee43";
}

.remixicon-opera-line:before {
  content: "\ee44";
}

.remixicon-order-play-fill:before {
  content: "\ee45";
}

.remixicon-order-play-line:before {
  content: "\ee46";
}

.remixicon-outlet-2-fill:before {
  content: "\ee47";
}

.remixicon-outlet-2-line:before {
  content: "\ee48";
}

.remixicon-outlet-fill:before {
  content: "\ee49";
}

.remixicon-outlet-line:before {
  content: "\ee4a";
}

.remixicon-page-separator:before {
  content: "\ee4b";
}

.remixicon-pages-fill:before {
  content: "\ee4c";
}

.remixicon-pages-line:before {
  content: "\ee4d";
}

.remixicon-paint-brush-fill:before {
  content: "\ee4e";
}

.remixicon-paint-brush-line:before {
  content: "\ee4f";
}

.remixicon-paint-fill:before {
  content: "\ee50";
}

.remixicon-paint-line:before {
  content: "\ee51";
}

.remixicon-palette-fill:before {
  content: "\ee52";
}

.remixicon-palette-line:before {
  content: "\ee53";
}

.remixicon-pantone-fill:before {
  content: "\ee54";
}

.remixicon-pantone-line:before {
  content: "\ee55";
}

.remixicon-paragraph:before {
  content: "\ee56";
}

.remixicon-parent-fill:before {
  content: "\ee57";
}

.remixicon-parent-line:before {
  content: "\ee58";
}

.remixicon-parentheses-fill:before {
  content: "\ee59";
}

.remixicon-parentheses-line:before {
  content: "\ee5a";
}

.remixicon-parking-box-fill:before {
  content: "\ee5b";
}

.remixicon-parking-box-line:before {
  content: "\ee5c";
}

.remixicon-parking-fill:before {
  content: "\ee5d";
}

.remixicon-parking-line:before {
  content: "\ee5e";
}

.remixicon-patreon-fill:before {
  content: "\ee5f";
}

.remixicon-patreon-line:before {
  content: "\ee60";
}

.remixicon-pause-circle-fill:before {
  content: "\ee61";
}

.remixicon-pause-circle-line:before {
  content: "\ee62";
}

.remixicon-pause-fill:before {
  content: "\ee63";
}

.remixicon-pause-line:before {
  content: "\ee64";
}

.remixicon-pause-mini-fill:before {
  content: "\ee65";
}

.remixicon-pause-mini-line:before {
  content: "\ee66";
}

.remixicon-paypal-fill:before {
  content: "\ee67";
}

.remixicon-paypal-line:before {
  content: "\ee68";
}

.remixicon-pen-nib-fill:before {
  content: "\ee69";
}

.remixicon-pen-nib-line:before {
  content: "\ee6a";
}

.remixicon-pencil-fill:before {
  content: "\ee6b";
}

.remixicon-pencil-line:before {
  content: "\ee6c";
}

.remixicon-pencil-ruler-2-fill:before {
  content: "\ee6d";
}

.remixicon-pencil-ruler-2-line:before {
  content: "\ee6e";
}

.remixicon-pencil-ruler-fill:before {
  content: "\ee6f";
}

.remixicon-pencil-ruler-line:before {
  content: "\ee70";
}

.remixicon-percent-fill:before {
  content: "\ee71";
}

.remixicon-percent-line:before {
  content: "\ee72";
}

.remixicon-phone-camera-fill:before {
  content: "\ee73";
}

.remixicon-phone-camera-line:before {
  content: "\ee74";
}

.remixicon-phone-fill:before {
  content: "\ee75";
}

.remixicon-phone-line:before {
  content: "\ee76";
}

.remixicon-pie-chart-2-fill:before {
  content: "\ee77";
}

.remixicon-pie-chart-2-line:before {
  content: "\ee78";
}

.remixicon-pie-chart-box-fill:before {
  content: "\ee79";
}

.remixicon-pie-chart-box-line:before {
  content: "\ee7a";
}

.remixicon-pie-chart-fill:before {
  content: "\ee7b";
}

.remixicon-pie-chart-line:before {
  content: "\ee7c";
}

.remixicon-pin-distance-fill:before {
  content: "\ee7d";
}

.remixicon-pin-distance-line:before {
  content: "\ee7e";
}

.remixicon-pinterest-fill:before {
  content: "\ee7f";
}

.remixicon-pinterest-line:before {
  content: "\ee80";
}

.remixicon-plane-fill:before {
  content: "\ee81";
}

.remixicon-plane-line:before {
  content: "\ee82";
}

.remixicon-play-circle-fill:before {
  content: "\ee83";
}

.remixicon-play-circle-line:before {
  content: "\ee84";
}

.remixicon-play-fill:before {
  content: "\ee85";
}

.remixicon-play-line:before {
  content: "\ee86";
}

.remixicon-play-list-add-fill:before {
  content: "\ee87";
}

.remixicon-play-list-add-line:before {
  content: "\ee88";
}

.remixicon-play-list-fill:before {
  content: "\ee89";
}

.remixicon-play-list-line:before {
  content: "\ee8a";
}

.remixicon-play-mini-fill:before {
  content: "\ee8b";
}

.remixicon-play-mini-line:before {
  content: "\ee8c";
}

.remixicon-playstation-fill:before {
  content: "\ee8d";
}

.remixicon-playstation-line:before {
  content: "\ee8e";
}

.remixicon-plug-2-fill:before {
  content: "\ee8f";
}

.remixicon-plug-2-line:before {
  content: "\ee90";
}

.remixicon-plug-fill:before {
  content: "\ee91";
}

.remixicon-plug-line:before {
  content: "\ee92";
}

.remixicon-polaroid-2-fill:before {
  content: "\ee93";
}

.remixicon-polaroid-2-line:before {
  content: "\ee94";
}

.remixicon-polaroid-fill:before {
  content: "\ee95";
}

.remixicon-polaroid-line:before {
  content: "\ee96";
}

.remixicon-police-car-fill:before {
  content: "\ee97";
}

.remixicon-police-car-line:before {
  content: "\ee98";
}

.remixicon-price-tag-2-fill:before {
  content: "\ee99";
}

.remixicon-price-tag-2-line:before {
  content: "\ee9a";
}

.remixicon-price-tag-3-fill:before {
  content: "\ee9b";
}

.remixicon-price-tag-3-line:before {
  content: "\ee9c";
}

.remixicon-price-tag-fill:before {
  content: "\ee9d";
}

.remixicon-price-tag-line:before {
  content: "\ee9e";
}

.remixicon-printer-fill:before {
  content: "\ee9f";
}

.remixicon-printer-line:before {
  content: "\eea0";
}

.remixicon-product-hunt-fill:before {
  content: "\eea1";
}

.remixicon-product-hunt-line:before {
  content: "\eea2";
}

.remixicon-profile-fill:before {
  content: "\eea3";
}

.remixicon-profile-line:before {
  content: "\eea4";
}

.remixicon-projector-2-fill:before {
  content: "\eea5";
}

.remixicon-projector-2-line:before {
  content: "\eea6";
}

.remixicon-projector-fill:before {
  content: "\eea7";
}

.remixicon-projector-line:before {
  content: "\eea8";
}

.remixicon-qq-fill:before {
  content: "\eea9";
}

.remixicon-qq-line:before {
  content: "\eeaa";
}

.remixicon-qr-code-fill:before {
  content: "\eeab";
}

.remixicon-qr-code-line:before {
  content: "\eeac";
}

.remixicon-qr-scan-2-fill:before {
  content: "\eead";
}

.remixicon-qr-scan-2-line:before {
  content: "\eeae";
}

.remixicon-qr-scan-fill:before {
  content: "\eeaf";
}

.remixicon-qr-scan-line:before {
  content: "\eeb0";
}

.remixicon-question-answer-fill:before {
  content: "\eeb1";
}

.remixicon-question-answer-line:before {
  content: "\eeb2";
}

.remixicon-question-fill:before {
  content: "\eeb3";
}

.remixicon-question-line:before {
  content: "\eeb4";
}

.remixicon-questionnaire-fill:before {
  content: "\eeb5";
}

.remixicon-questionnaire-line:before {
  content: "\eeb6";
}

.remixicon-quill-pen-fill:before {
  content: "\eeb7";
}

.remixicon-quill-pen-line:before {
  content: "\eeb8";
}

.remixicon-radar-fill:before {
  content: "\eeb9";
}

.remixicon-radar-line:before {
  content: "\eeba";
}

.remixicon-radio-2-fill:before {
  content: "\eebb";
}

.remixicon-radio-2-line:before {
  content: "\eebc";
}

.remixicon-radio-button-fill:before {
  content: "\eebd";
}

.remixicon-radio-button-line:before {
  content: "\eebe";
}

.remixicon-radio-fill:before {
  content: "\eebf";
}

.remixicon-radio-line:before {
  content: "\eec0";
}

.remixicon-rainy-fill:before {
  content: "\eec1";
}

.remixicon-rainy-line:before {
  content: "\eec2";
}

.remixicon-reactjs-fill:before {
  content: "\eec3";
}

.remixicon-reactjs-line:before {
  content: "\eec4";
}

.remixicon-record-circle-fill:before {
  content: "\eec5";
}

.remixicon-record-circle-line:before {
  content: "\eec6";
}

.remixicon-record-mail-fill:before {
  content: "\eec7";
}

.remixicon-record-mail-line:before {
  content: "\eec8";
}

.remixicon-red-packet-fill:before {
  content: "\eec9";
}

.remixicon-red-packet-line:before {
  content: "\eeca";
}

.remixicon-reddit-fill:before {
  content: "\eecb";
}

.remixicon-reddit-line:before {
  content: "\eecc";
}

.remixicon-refresh-fill:before {
  content: "\eecd";
}

.remixicon-refresh-line:before {
  content: "\eece";
}

.remixicon-refund-fill:before {
  content: "\eecf";
}

.remixicon-refund-line:before {
  content: "\eed0";
}

.remixicon-remixicon-fill:before {
  content: "\eed1";
}

.remixicon-remixicon-line:before {
  content: "\eed2";
}

.remixicon-repeat-2-fill:before {
  content: "\eed3";
}

.remixicon-repeat-2-line:before {
  content: "\eed4";
}

.remixicon-repeat-fill:before {
  content: "\eed5";
}

.remixicon-repeat-line:before {
  content: "\eed6";
}

.remixicon-repeat-one-fill:before {
  content: "\eed7";
}

.remixicon-repeat-one-line:before {
  content: "\eed8";
}

.remixicon-reply-fill:before {
  content: "\eed9";
}

.remixicon-reply-line:before {
  content: "\eeda";
}

.remixicon-reserved-fill:before {
  content: "\eedb";
}

.remixicon-reserved-line:before {
  content: "\eedc";
}

.remixicon-restart-fill:before {
  content: "\eedd";
}

.remixicon-restart-line:before {
  content: "\eede";
}

.remixicon-restaurant-2-fill:before {
  content: "\eedf";
}

.remixicon-restaurant-2-line:before {
  content: "\eee0";
}

.remixicon-restaurant-fill:before {
  content: "\eee1";
}

.remixicon-restaurant-line:before {
  content: "\eee2";
}

.remixicon-rewind-fill:before {
  content: "\eee3";
}

.remixicon-rewind-line:before {
  content: "\eee4";
}

.remixicon-rewind-mini-fill:before {
  content: "\eee5";
}

.remixicon-rewind-mini-line:before {
  content: "\eee6";
}

.remixicon-rhythm-fill:before {
  content: "\eee7";
}

.remixicon-rhythm-line:before {
  content: "\eee8";
}

.remixicon-riding-fill:before {
  content: "\eee9";
}

.remixicon-riding-line:before {
  content: "\eeea";
}

.remixicon-road-map-fill:before {
  content: "\eeeb";
}

.remixicon-road-map-line:before {
  content: "\eeec";
}

.remixicon-roadster-fill:before {
  content: "\eeed";
}

.remixicon-roadster-line:before {
  content: "\eeee";
}

.remixicon-robot-fill:before {
  content: "\eeef";
}

.remixicon-robot-line:before {
  content: "\eef0";
}

.remixicon-rocket-2-fill:before {
  content: "\eef1";
}

.remixicon-rocket-2-line:before {
  content: "\eef2";
}

.remixicon-rocket-fill:before {
  content: "\eef3";
}

.remixicon-rocket-line:before {
  content: "\eef4";
}

.remixicon-route-fill:before {
  content: "\eef5";
}

.remixicon-route-line:before {
  content: "\eef6";
}

.remixicon-router-fill:before {
  content: "\eef7";
}

.remixicon-router-line:before {
  content: "\eef8";
}

.remixicon-rss-fill:before {
  content: "\eef9";
}

.remixicon-rss-line:before {
  content: "\eefa";
}

.remixicon-ruler-2-fill:before {
  content: "\eefb";
}

.remixicon-ruler-2-line:before {
  content: "\eefc";
}

.remixicon-ruler-fill:before {
  content: "\eefd";
}

.remixicon-ruler-line:before {
  content: "\eefe";
}

.remixicon-run-fill:before {
  content: "\eeff";
}

.remixicon-run-line:before {
  content: "\ef00";
}

.remixicon-safari-fill:before {
  content: "\ef01";
}

.remixicon-safari-line:before {
  content: "\ef02";
}

.remixicon-safe-2-fill:before {
  content: "\ef03";
}

.remixicon-safe-2-line:before {
  content: "\ef04";
}

.remixicon-safe-fill:before {
  content: "\ef05";
}

.remixicon-safe-line:before {
  content: "\ef06";
}

.remixicon-sailboat-fill:before {
  content: "\ef07";
}

.remixicon-sailboat-line:before {
  content: "\ef08";
}

.remixicon-save-2-fill:before {
  content: "\ef09";
}

.remixicon-save-2-line:before {
  content: "\ef0a";
}

.remixicon-save-3-fill:before {
  content: "\ef0b";
}

.remixicon-save-3-line:before {
  content: "\ef0c";
}

.remixicon-save-fill:before {
  content: "\ef0d";
}

.remixicon-save-line:before {
  content: "\ef0e";
}

.remixicon-scan-2-fill:before {
  content: "\ef0f";
}

.remixicon-scan-2-line:before {
  content: "\ef10";
}

.remixicon-scan-fill:before {
  content: "\ef11";
}

.remixicon-scan-line:before {
  content: "\ef12";
}

.remixicon-scissors-2-fill:before {
  content: "\ef13";
}

.remixicon-scissors-2-line:before {
  content: "\ef14";
}

.remixicon-scissors-cut-fill:before {
  content: "\ef15";
}

.remixicon-scissors-cut-line:before {
  content: "\ef16";
}

.remixicon-scissors-fill:before {
  content: "\ef17";
}

.remixicon-scissors-line:before {
  content: "\ef18";
}

.remixicon-screenshot-2-fill:before {
  content: "\ef19";
}

.remixicon-screenshot-2-line:before {
  content: "\ef1a";
}

.remixicon-screenshot-fill:before {
  content: "\ef1b";
}

.remixicon-screenshot-line:before {
  content: "\ef1c";
}

.remixicon-sd-card-fill:before {
  content: "\ef1d";
}

.remixicon-sd-card-line:before {
  content: "\ef1e";
}

.remixicon-sd-card-mini-fill:before {
  content: "\ef1f";
}

.remixicon-sd-card-mini-line:before {
  content: "\ef20";
}

.remixicon-search-2-fill:before {
  content: "\ef21";
}

.remixicon-search-2-line:before {
  content: "\ef22";
}

.remixicon-search-eye-fill:before {
  content: "\ef23";
}

.remixicon-search-eye-line:before {
  content: "\ef24";
}

.remixicon-search-fill:before {
  content: "\ef25";
}

.remixicon-search-line:before {
  content: "\ef26";
}

.remixicon-send-plane-2-fill:before {
  content: "\ef27";
}

.remixicon-send-plane-2-line:before {
  content: "\ef28";
}

.remixicon-send-plane-fill:before {
  content: "\ef29";
}

.remixicon-send-plane-line:before {
  content: "\ef2a";
}

.remixicon-sensor-fill:before {
  content: "\ef2b";
}

.remixicon-sensor-line:before {
  content: "\ef2c";
}

.remixicon-separator:before {
  content: "\ef2d";
}

.remixicon-server-fill:before {
  content: "\ef2e";
}

.remixicon-server-line:before {
  content: "\ef2f";
}

.remixicon-settings-2-fill:before {
  content: "\ef30";
}

.remixicon-settings-2-line:before {
  content: "\ef31";
}

.remixicon-settings-3-fill:before {
  content: "\ef32";
}

.remixicon-settings-3-line:before {
  content: "\ef33";
}

.remixicon-settings-4-fill:before {
  content: "\ef34";
}

.remixicon-settings-4-line:before {
  content: "\ef35";
}

.remixicon-settings-5-fill:before {
  content: "\ef36";
}

.remixicon-settings-5-line:before {
  content: "\ef37";
}

.remixicon-settings-6-fill:before {
  content: "\ef38";
}

.remixicon-settings-6-line:before {
  content: "\ef39";
}

.remixicon-settings-fill:before {
  content: "\ef3a";
}

.remixicon-settings-line:before {
  content: "\ef3b";
}

.remixicon-shape-2-fill:before {
  content: "\ef3c";
}

.remixicon-shape-2-line:before {
  content: "\ef3d";
}

.remixicon-shape-fill:before {
  content: "\ef3e";
}

.remixicon-shape-line:before {
  content: "\ef3f";
}

.remixicon-share-box-fill:before {
  content: "\ef40";
}

.remixicon-share-box-line:before {
  content: "\ef41";
}

.remixicon-share-circle-fill:before {
  content: "\ef42";
}

.remixicon-share-circle-line:before {
  content: "\ef43";
}

.remixicon-share-fill:before {
  content: "\ef44";
}

.remixicon-share-forward-2-fill:before {
  content: "\ef45";
}

.remixicon-share-forward-2-line:before {
  content: "\ef46";
}

.remixicon-share-forward-box-fill:before {
  content: "\ef47";
}

.remixicon-share-forward-box-line:before {
  content: "\ef48";
}

.remixicon-share-forward-fill:before {
  content: "\ef49";
}

.remixicon-share-forward-line:before {
  content: "\ef4a";
}

.remixicon-share-line:before {
  content: "\ef4b";
}

.remixicon-shield-cross-fill:before {
  content: "\ef4c";
}

.remixicon-shield-cross-line:before {
  content: "\ef4d";
}

.remixicon-shield-fill:before {
  content: "\ef4e";
}

.remixicon-shield-flash-fill:before {
  content: "\ef4f";
}

.remixicon-shield-flash-line:before {
  content: "\ef50";
}

.remixicon-shield-line:before {
  content: "\ef51";
}

.remixicon-shield-star-fill:before {
  content: "\ef52";
}

.remixicon-shield-star-line:before {
  content: "\ef53";
}

.remixicon-shield-user-fill:before {
  content: "\ef54";
}

.remixicon-shield-user-line:before {
  content: "\ef55";
}

.remixicon-ship-2-fill:before {
  content: "\ef56";
}

.remixicon-ship-2-line:before {
  content: "\ef57";
}

.remixicon-ship-fill:before {
  content: "\ef58";
}

.remixicon-ship-line:before {
  content: "\ef59";
}

.remixicon-shirt-fill:before {
  content: "\ef5a";
}

.remixicon-shirt-line:before {
  content: "\ef5b";
}

.remixicon-shopping-bag-2-fill:before {
  content: "\ef5c";
}

.remixicon-shopping-bag-2-line:before {
  content: "\ef5d";
}

.remixicon-shopping-bag-3-fill:before {
  content: "\ef5e";
}

.remixicon-shopping-bag-3-line:before {
  content: "\ef5f";
}

.remixicon-shopping-bag-fill:before {
  content: "\ef60";
}

.remixicon-shopping-bag-line:before {
  content: "\ef61";
}

.remixicon-shopping-cart-2-fill:before {
  content: "\ef62";
}

.remixicon-shopping-cart-2-line:before {
  content: "\ef63";
}

.remixicon-shopping-cart-fill:before {
  content: "\ef64";
}

.remixicon-shopping-cart-line:before {
  content: "\ef65";
}

.remixicon-showers-fill:before {
  content: "\ef66";
}

.remixicon-showers-line:before {
  content: "\ef67";
}

.remixicon-shuffle-fill:before {
  content: "\ef68";
}

.remixicon-shuffle-line:before {
  content: "\ef69";
}

.remixicon-shut-down-fill:before {
  content: "\ef6a";
}

.remixicon-shut-down-line:before {
  content: "\ef6b";
}

.remixicon-side-bar-fill:before {
  content: "\ef6c";
}

.remixicon-side-bar-line:before {
  content: "\ef6d";
}

.remixicon-signal-tower-fill:before {
  content: "\ef6e";
}

.remixicon-signal-tower-line:before {
  content: "\ef6f";
}

.remixicon-sim-card-2-fill:before {
  content: "\ef70";
}

.remixicon-sim-card-2-line:before {
  content: "\ef71";
}

.remixicon-sim-card-fill:before {
  content: "\ef72";
}

.remixicon-sim-card-line:before {
  content: "\ef73";
}

.remixicon-single-quotes-l:before {
  content: "\ef74";
}

.remixicon-single-quotes-r:before {
  content: "\ef75";
}

.remixicon-sip-fill:before {
  content: "\ef76";
}

.remixicon-sip-line:before {
  content: "\ef77";
}

.remixicon-skip-back-fill:before {
  content: "\ef78";
}

.remixicon-skip-back-line:before {
  content: "\ef79";
}

.remixicon-skip-back-mini-fill:before {
  content: "\ef7a";
}

.remixicon-skip-back-mini-line:before {
  content: "\ef7b";
}

.remixicon-skip-forward-fill:before {
  content: "\ef7c";
}

.remixicon-skip-forward-line:before {
  content: "\ef7d";
}

.remixicon-skip-forward-mini-fill:before {
  content: "\ef7e";
}

.remixicon-skip-forward-mini-line:before {
  content: "\ef7f";
}

.remixicon-skull-fill:before {
  content: "\ef80";
}

.remixicon-skull-line:before {
  content: "\ef81";
}

.remixicon-skype-fill:before {
  content: "\ef82";
}

.remixicon-skype-line:before {
  content: "\ef83";
}

.remixicon-slack-fill:before {
  content: "\ef84";
}

.remixicon-slack-line:before {
  content: "\ef85";
}

.remixicon-slice-fill:before {
  content: "\ef86";
}

.remixicon-slice-line:before {
  content: "\ef87";
}

.remixicon-slideshow-2-fill:before {
  content: "\ef88";
}

.remixicon-slideshow-2-line:before {
  content: "\ef89";
}

.remixicon-slideshow-3-fill:before {
  content: "\ef8a";
}

.remixicon-slideshow-3-line:before {
  content: "\ef8b";
}

.remixicon-slideshow-4-fill:before {
  content: "\ef8c";
}

.remixicon-slideshow-4-line:before {
  content: "\ef8d";
}

.remixicon-slideshow-fill:before {
  content: "\ef8e";
}

.remixicon-slideshow-line:before {
  content: "\ef8f";
}

.remixicon-smartphone-fill:before {
  content: "\ef90";
}

.remixicon-smartphone-line:before {
  content: "\ef91";
}

.remixicon-snapchat-fill:before {
  content: "\ef92";
}

.remixicon-snapchat-line:before {
  content: "\ef93";
}

.remixicon-snowy-fill:before {
  content: "\ef94";
}

.remixicon-snowy-line:before {
  content: "\ef95";
}

.remixicon-sound-module-fill:before {
  content: "\ef96";
}

.remixicon-sound-module-line:before {
  content: "\ef97";
}

.remixicon-space-ship-fill:before {
  content: "\ef98";
}

.remixicon-space-ship-line:before {
  content: "\ef99";
}

.remixicon-space:before {
  content: "\ef9a";
}

.remixicon-spam-2-fill:before {
  content: "\ef9b";
}

.remixicon-spam-2-line:before {
  content: "\ef9c";
}

.remixicon-spam-3-fill:before {
  content: "\ef9d";
}

.remixicon-spam-3-line:before {
  content: "\ef9e";
}

.remixicon-spam-fill:before {
  content: "\ef9f";
}

.remixicon-spam-line:before {
  content: "\efa0";
}

.remixicon-speaker-2-fill:before {
  content: "\efa1";
}

.remixicon-speaker-2-line:before {
  content: "\efa2";
}

.remixicon-speaker-3-fill:before {
  content: "\efa3";
}

.remixicon-speaker-3-line:before {
  content: "\efa4";
}

.remixicon-speaker-fill:before {
  content: "\efa5";
}

.remixicon-speaker-line:before {
  content: "\efa6";
}

.remixicon-speed-fill:before {
  content: "\efa7";
}

.remixicon-speed-line:before {
  content: "\efa8";
}

.remixicon-speed-mini-fill:before {
  content: "\efa9";
}

.remixicon-speed-mini-line:before {
  content: "\efaa";
}

.remixicon-spotify-fill:before {
  content: "\efab";
}

.remixicon-spotify-line:before {
  content: "\efac";
}

.remixicon-stack-fill:before {
  content: "\efad";
}

.remixicon-stack-line:before {
  content: "\efae";
}

.remixicon-stack-overflow-fill:before {
  content: "\efaf";
}

.remixicon-stack-overflow-line:before {
  content: "\efb0";
}

.remixicon-star-fill:before {
  content: "\efb1";
}

.remixicon-star-half-fill:before {
  content: "\efb2";
}

.remixicon-star-half-line:before {
  content: "\efb3";
}

.remixicon-star-half-s-fill:before {
  content: "\efb4";
}

.remixicon-star-half-s-line:before {
  content: "\efb5";
}

.remixicon-star-line:before {
  content: "\efb6";
}

.remixicon-star-s-fill:before {
  content: "\efb7";
}

.remixicon-star-s-line:before {
  content: "\efb8";
}

.remixicon-stock-fill:before {
  content: "\efb9";
}

.remixicon-stock-line:before {
  content: "\efba";
}

.remixicon-stop-circle-fill:before {
  content: "\efbb";
}

.remixicon-stop-circle-line:before {
  content: "\efbc";
}

.remixicon-stop-fill:before {
  content: "\efbd";
}

.remixicon-stop-line:before {
  content: "\efbe";
}

.remixicon-stop-mini-fill:before {
  content: "\efbf";
}

.remixicon-stop-mini-line:before {
  content: "\efc0";
}

.remixicon-store-2-fill:before {
  content: "\efc1";
}

.remixicon-store-2-line:before {
  content: "\efc2";
}

.remixicon-store-3-fill:before {
  content: "\efc3";
}

.remixicon-store-3-line:before {
  content: "\efc4";
}

.remixicon-store-fill:before {
  content: "\efc5";
}

.remixicon-store-line:before {
  content: "\efc6";
}

.remixicon-strikethrough-2:before {
  content: "\efc7";
}

.remixicon-strikethrough:before {
  content: "\efc8";
}

.remixicon-subscript-2:before {
  content: "\efc9";
}

.remixicon-subscript:before {
  content: "\efca";
}

.remixicon-subtract-fill:before {
  content: "\efcb";
}

.remixicon-subtract-line:before {
  content: "\efcc";
}

.remixicon-subway-fill:before {
  content: "\efcd";
}

.remixicon-subway-line:before {
  content: "\efce";
}

.remixicon-sun-cloudy-fill:before {
  content: "\efcf";
}

.remixicon-sun-cloudy-line:before {
  content: "\efd0";
}

.remixicon-sun-fill:before {
  content: "\efd1";
}

.remixicon-sun-foggy-fill:before {
  content: "\efd2";
}

.remixicon-sun-foggy-line:before {
  content: "\efd3";
}

.remixicon-sun-line:before {
  content: "\efd4";
}

.remixicon-superscript-2:before {
  content: "\efd5";
}

.remixicon-superscript:before {
  content: "\efd6";
}

.remixicon-surround-sound-fill:before {
  content: "\efd7";
}

.remixicon-surround-sound-line:before {
  content: "\efd8";
}

.remixicon-swap-box-fill:before {
  content: "\efd9";
}

.remixicon-swap-box-line:before {
  content: "\efda";
}

.remixicon-swap-fill:before {
  content: "\efdb";
}

.remixicon-swap-line:before {
  content: "\efdc";
}

.remixicon-switch-fill:before {
  content: "\efdd";
}

.remixicon-switch-line:before {
  content: "\efde";
}

.remixicon-t-box-fill:before {
  content: "\efdf";
}

.remixicon-t-box-line:before {
  content: "\efe0";
}

.remixicon-t-shirt-fill:before {
  content: "\efe1";
}

.remixicon-t-shirt-line:before {
  content: "\efe2";
}

.remixicon-table-2:before {
  content: "\efe3";
}

.remixicon-table-fill:before {
  content: "\efe4";
}

.remixicon-table-line:before {
  content: "\efe5";
}

.remixicon-tablet-fill:before {
  content: "\efe6";
}

.remixicon-tablet-line:before {
  content: "\efe7";
}

.remixicon-taobao-fill:before {
  content: "\efe8";
}

.remixicon-taobao-line:before {
  content: "\efe9";
}

.remixicon-tape-fill:before {
  content: "\efea";
}

.remixicon-tape-line:before {
  content: "\efeb";
}

.remixicon-task-fill:before {
  content: "\efec";
}

.remixicon-task-line:before {
  content: "\efed";
}

.remixicon-taxi-fill:before {
  content: "\efee";
}

.remixicon-taxi-line:before {
  content: "\efef";
}

.remixicon-telegram-fill:before {
  content: "\eff0";
}

.remixicon-telegram-line:before {
  content: "\eff1";
}

.remixicon-temp-cold-fill:before {
  content: "\eff2";
}

.remixicon-temp-cold-line:before {
  content: "\eff3";
}

.remixicon-temp-hot-fill:before {
  content: "\eff4";
}

.remixicon-temp-hot-line:before {
  content: "\eff5";
}

.remixicon-terminal-box-fill:before {
  content: "\eff6";
}

.remixicon-terminal-box-line:before {
  content: "\eff7";
}

.remixicon-terminal-fill:before {
  content: "\eff8";
}

.remixicon-terminal-line:before {
  content: "\eff9";
}

.remixicon-terminal-window-fill:before {
  content: "\effa";
}

.remixicon-terminal-window-line:before {
  content: "\effb";
}

.remixicon-text-direction-l:before {
  content: "\effc";
}

.remixicon-text-direction-r:before {
  content: "\effd";
}

.remixicon-text-spacing:before {
  content: "\effe";
}

.remixicon-text-wrap:before {
  content: "\efff";
}

.remixicon-text:before {
  content: "\f000";
}

.remixicon-thumb-down-fill:before {
  content: "\f001";
}

.remixicon-thumb-down-line:before {
  content: "\f002";
}

.remixicon-thumb-up-fill:before {
  content: "\f003";
}

.remixicon-thumb-up-line:before {
  content: "\f004";
}

.remixicon-thunderstorms-fill:before {
  content: "\f005";
}

.remixicon-thunderstorms-line:before {
  content: "\f006";
}

.remixicon-time-fill:before {
  content: "\f007";
}

.remixicon-time-line:before {
  content: "\f008";
}

.remixicon-timer-2-fill:before {
  content: "\f009";
}

.remixicon-timer-2-line:before {
  content: "\f00a";
}

.remixicon-timer-fill:before {
  content: "\f00b";
}

.remixicon-timer-flash-fill:before {
  content: "\f00c";
}

.remixicon-timer-flash-line:before {
  content: "\f00d";
}

.remixicon-timer-line:before {
  content: "\f00e";
}

.remixicon-todo-fill:before {
  content: "\f00f";
}

.remixicon-todo-line:before {
  content: "\f010";
}

.remixicon-toggle-fill:before {
  content: "\f011";
}

.remixicon-toggle-line:before {
  content: "\f012";
}

.remixicon-tornado-fill:before {
  content: "\f013";
}

.remixicon-tornado-line:before {
  content: "\f014";
}

.remixicon-traffic-light-fill:before {
  content: "\f015";
}

.remixicon-traffic-light-line:before {
  content: "\f016";
}

.remixicon-train-fill:before {
  content: "\f017";
}

.remixicon-train-line:before {
  content: "\f018";
}

.remixicon-travesti-fill:before {
  content: "\f019";
}

.remixicon-travesti-line:before {
  content: "\f01a";
}

.remixicon-treasure-map-fill:before {
  content: "\f01b";
}

.remixicon-treasure-map-line:before {
  content: "\f01c";
}

.remixicon-trello-fill:before {
  content: "\f01d";
}

.remixicon-trello-line:before {
  content: "\f01e";
}

.remixicon-trophy-fill:before {
  content: "\f01f";
}

.remixicon-trophy-line:before {
  content: "\f020";
}

.remixicon-truck-fill:before {
  content: "\f021";
}

.remixicon-truck-line:before {
  content: "\f022";
}

.remixicon-tumblr-fill:before {
  content: "\f023";
}

.remixicon-tumblr-line:before {
  content: "\f024";
}

.remixicon-tv-2-fill:before {
  content: "\f025";
}

.remixicon-tv-2-line:before {
  content: "\f026";
}

.remixicon-tv-fill:before {
  content: "\f027";
}

.remixicon-tv-line:before {
  content: "\f028";
}

.remixicon-twitter-fill:before {
  content: "\f029";
}

.remixicon-twitter-line:before {
  content: "\f02a";
}

.remixicon-u-disk-fill:before {
  content: "\f02b";
}

.remixicon-u-disk-line:before {
  content: "\f02c";
}

.remixicon-ubuntu-fill:before {
  content: "\f02d";
}

.remixicon-ubuntu-line:before {
  content: "\f02e";
}

.remixicon-umbrella-fill:before {
  content: "\f02f";
}

.remixicon-umbrella-line:before {
  content: "\f030";
}

.remixicon-underline:before {
  content: "\f031";
}

.remixicon-upload-2-fill:before {
  content: "\f032";
}

.remixicon-upload-2-line:before {
  content: "\f033";
}

.remixicon-upload-cloud-fill:before {
  content: "\f034";
}

.remixicon-upload-cloud-line:before {
  content: "\f035";
}

.remixicon-upload-fill:before {
  content: "\f036";
}

.remixicon-upload-line:before {
  content: "\f037";
}

.remixicon-user-2-fill:before {
  content: "\f038";
}

.remixicon-user-2-line:before {
  content: "\f039";
}

.remixicon-user-3-fill:before {
  content: "\f03a";
}

.remixicon-user-3-line:before {
  content: "\f03b";
}

.remixicon-user-4-fill:before {
  content: "\f03c";
}

.remixicon-user-4-line:before {
  content: "\f03d";
}

.remixicon-user-5-fill:before {
  content: "\f03e";
}

.remixicon-user-5-line:before {
  content: "\f03f";
}

.remixicon-user-add-fill:before {
  content: "\f040";
}

.remixicon-user-add-line:before {
  content: "\f041";
}

.remixicon-user-fill:before {
  content: "\f042";
}

.remixicon-user-follow-fill:before {
  content: "\f043";
}

.remixicon-user-follow-line:before {
  content: "\f044";
}

.remixicon-user-line:before {
  content: "\f045";
}

.remixicon-user-location-fill:before {
  content: "\f046";
}

.remixicon-user-location-line:before {
  content: "\f047";
}

.remixicon-user-received-2-fill:before {
  content: "\f048";
}

.remixicon-user-received-2-line:before {
  content: "\f049";
}

.remixicon-user-received-fill:before {
  content: "\f04a";
}

.remixicon-user-received-line:before {
  content: "\f04b";
}

.remixicon-user-search-fill:before {
  content: "\f04c";
}

.remixicon-user-search-line:before {
  content: "\f04d";
}

.remixicon-user-settings-fill:before {
  content: "\f04e";
}

.remixicon-user-settings-line:before {
  content: "\f04f";
}

.remixicon-user-shared-2-fill:before {
  content: "\f050";
}

.remixicon-user-shared-2-line:before {
  content: "\f051";
}

.remixicon-user-shared-fill:before {
  content: "\f052";
}

.remixicon-user-shared-line:before {
  content: "\f053";
}

.remixicon-user-smile-fill:before {
  content: "\f054";
}

.remixicon-user-smile-line:before {
  content: "\f055";
}

.remixicon-user-star-fill:before {
  content: "\f056";
}

.remixicon-user-star-line:before {
  content: "\f057";
}

.remixicon-user-unfollow-fill:before {
  content: "\f058";
}

.remixicon-user-unfollow-line:before {
  content: "\f059";
}

.remixicon-user-voice-fill:before {
  content: "\f05a";
}

.remixicon-user-voice-line:before {
  content: "\f05b";
}

.remixicon-video-chat-fill:before {
  content: "\f05c";
}

.remixicon-video-chat-line:before {
  content: "\f05d";
}

.remixicon-video-fill:before {
  content: "\f05e";
}

.remixicon-video-line:before {
  content: "\f05f";
}

.remixicon-vidicon-2-fill:before {
  content: "\f060";
}

.remixicon-vidicon-2-line:before {
  content: "\f061";
}

.remixicon-vidicon-fill:before {
  content: "\f062";
}

.remixicon-vidicon-line:before {
  content: "\f063";
}

.remixicon-vip-crown-2-fill:before {
  content: "\f064";
}

.remixicon-vip-crown-2-line:before {
  content: "\f065";
}

.remixicon-vip-crown-fill:before {
  content: "\f066";
}

.remixicon-vip-crown-line:before {
  content: "\f067";
}

.remixicon-vip-diamond-fill:before {
  content: "\f068";
}

.remixicon-vip-diamond-line:before {
  content: "\f069";
}

.remixicon-vip-fill:before {
  content: "\f06a";
}

.remixicon-vip-line:before {
  content: "\f06b";
}

.remixicon-visa-fill:before {
  content: "\f06c";
}

.remixicon-visa-line:before {
  content: "\f06d";
}

.remixicon-voiceprint-fill:before {
  content: "\f06e";
}

.remixicon-voiceprint-line:before {
  content: "\f06f";
}

.remixicon-volume-down-fill:before {
  content: "\f070";
}

.remixicon-volume-down-line:before {
  content: "\f071";
}

.remixicon-volume-mute-fill:before {
  content: "\f072";
}

.remixicon-volume-mute-line:before {
  content: "\f073";
}

.remixicon-volume-up-fill:before {
  content: "\f074";
}

.remixicon-volume-up-line:before {
  content: "\f075";
}

.remixicon-vuejs-fill:before {
  content: "\f076";
}

.remixicon-vuejs-line:before {
  content: "\f077";
}

.remixicon-walk-fill:before {
  content: "\f078";
}

.remixicon-walk-line:before {
  content: "\f079";
}

.remixicon-wallet-2-fill:before {
  content: "\f07a";
}

.remixicon-wallet-2-line:before {
  content: "\f07b";
}

.remixicon-wallet-3-fill:before {
  content: "\f07c";
}

.remixicon-wallet-3-line:before {
  content: "\f07d";
}

.remixicon-wallet-fill:before {
  content: "\f07e";
}

.remixicon-wallet-line:before {
  content: "\f07f";
}

.remixicon-water-flash-fill:before {
  content: "\f080";
}

.remixicon-water-flash-line:before {
  content: "\f081";
}

.remixicon-webcam-fill:before {
  content: "\f082";
}

.remixicon-webcam-line:before {
  content: "\f083";
}

.remixicon-wechat-2-fill:before {
  content: "\f084";
}

.remixicon-wechat-2-line:before {
  content: "\f085";
}

.remixicon-wechat-fill:before {
  content: "\f086";
}

.remixicon-wechat-line:before {
  content: "\f087";
}

.remixicon-wechat-pay-fill:before {
  content: "\f088";
}

.remixicon-wechat-pay-line:before {
  content: "\f089";
}

.remixicon-weibo-fill:before {
  content: "\f08a";
}

.remixicon-weibo-line:before {
  content: "\f08b";
}

.remixicon-whatsapp-fill:before {
  content: "\f08c";
}

.remixicon-whatsapp-line:before {
  content: "\f08d";
}

.remixicon-wifi-fill:before {
  content: "\f08e";
}

.remixicon-wifi-line:before {
  content: "\f08f";
}

.remixicon-window-2-fill:before {
  content: "\f090";
}

.remixicon-window-2-line:before {
  content: "\f091";
}

.remixicon-window-fill:before {
  content: "\f092";
}

.remixicon-window-line:before {
  content: "\f093";
}

.remixicon-windows-fill:before {
  content: "\f094";
}

.remixicon-windows-line:before {
  content: "\f095";
}

.remixicon-windy-fill:before {
  content: "\f096";
}

.remixicon-windy-line:before {
  content: "\f097";
}

.remixicon-women-fill:before {
  content: "\f098";
}

.remixicon-women-line:before {
  content: "\f099";
}

.remixicon-xbox-fill:before {
  content: "\f09a";
}

.remixicon-xbox-line:before {
  content: "\f09b";
}

.remixicon-youtube-fill:before {
  content: "\f09c";
}

.remixicon-youtube-line:before {
  content: "\f09d";
}

.remixicon-zcool-fill:before {
  content: "\f09e";
}

.remixicon-zcool-line:before {
  content: "\f09f";
}

.remixicon-zhihu-fill:before {
  content: "\f0a0";
}

.remixicon-zhihu-line:before {
  content: "\f0a1";
}

.remixicon-zoom-in-fill:before {
  content: "\f0a2";
}

.remixicon-zoom-in-line:before {
  content: "\f0a3";
}

.remixicon-zoom-out-fill:before {
  content: "\f0a4";
}

.remixicon-zoom-out-line:before {
  content: "\f0a5";
}

@font-face {
  font-family: 'Pe-icon-7-stroke';
  src: url("../fonts/Pe-icon-7-stroke.eot?d7yf1v");
  src: url("../fonts/Pe-icon-7-stroke.eot?#iefixd7yf1v") format("embedded-opentype"), url("../fonts/Pe-icon-7-stroke.woff?d7yf1v") format("woff"), url("../fonts/Pe-icon-7-stroke.ttf?d7yf1v") format("truetype"), url("../fonts/Pe-icon-7-stroke.svg?d7yf1v#Pe-icon-7-stroke") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="pe-7s-"], [class*=" pe-7s-"] {
  display: inline-block;
  font-family: 'Pe-icon-7-stroke';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pe-7s-album:before {
  content: "\e6aa";
}

.pe-7s-arc:before {
  content: "\e6ab";
}

.pe-7s-back-2:before {
  content: "\e6ac";
}

.pe-7s-bandaid:before {
  content: "\e6ad";
}

.pe-7s-car:before {
  content: "\e6ae";
}

.pe-7s-diamond:before {
  content: "\e6af";
}

.pe-7s-door-lock:before {
  content: "\e6b0";
}

.pe-7s-eyedropper:before {
  content: "\e6b1";
}

.pe-7s-female:before {
  content: "\e6b2";
}

.pe-7s-gym:before {
  content: "\e6b3";
}

.pe-7s-hammer:before {
  content: "\e6b4";
}

.pe-7s-headphones:before {
  content: "\e6b5";
}

.pe-7s-helm:before {
  content: "\e6b6";
}

.pe-7s-hourglass:before {
  content: "\e6b7";
}

.pe-7s-leaf:before {
  content: "\e6b8";
}

.pe-7s-magic-wand:before {
  content: "\e6b9";
}

.pe-7s-male:before {
  content: "\e6ba";
}

.pe-7s-map-2:before {
  content: "\e6bb";
}

.pe-7s-next-2:before {
  content: "\e6bc";
}

.pe-7s-paint-bucket:before {
  content: "\e6bd";
}

.pe-7s-pendrive:before {
  content: "\e6be";
}

.pe-7s-photo:before {
  content: "\e6bf";
}

.pe-7s-piggy:before {
  content: "\e6c0";
}

.pe-7s-plugin:before {
  content: "\e6c1";
}

.pe-7s-refresh-2:before {
  content: "\e6c2";
}

.pe-7s-rocket:before {
  content: "\e6c3";
}

.pe-7s-settings:before {
  content: "\e6c4";
}

.pe-7s-shield:before {
  content: "\e6c5";
}

.pe-7s-smile:before {
  content: "\e6c6";
}

.pe-7s-usb:before {
  content: "\e6c7";
}

.pe-7s-vector:before {
  content: "\e6c8";
}

.pe-7s-wine:before {
  content: "\e6c9";
}

.pe-7s-cloud-upload:before {
  content: "\e68a";
}

.pe-7s-cash:before {
  content: "\e68c";
}

.pe-7s-close:before {
  content: "\e680";
}

.pe-7s-bluetooth:before {
  content: "\e68d";
}

.pe-7s-cloud-download:before {
  content: "\e68b";
}

.pe-7s-way:before {
  content: "\e68e";
}

.pe-7s-close-circle:before {
  content: "\e681";
}

.pe-7s-id:before {
  content: "\e68f";
}

.pe-7s-angle-up:before {
  content: "\e682";
}

.pe-7s-wristwatch:before {
  content: "\e690";
}

.pe-7s-angle-up-circle:before {
  content: "\e683";
}

.pe-7s-world:before {
  content: "\e691";
}

.pe-7s-angle-right:before {
  content: "\e684";
}

.pe-7s-volume:before {
  content: "\e692";
}

.pe-7s-angle-right-circle:before {
  content: "\e685";
}

.pe-7s-users:before {
  content: "\e693";
}

.pe-7s-angle-left:before {
  content: "\e686";
}

.pe-7s-user-female:before {
  content: "\e694";
}

.pe-7s-angle-left-circle:before {
  content: "\e687";
}

.pe-7s-up-arrow:before {
  content: "\e695";
}

.pe-7s-angle-down:before {
  content: "\e688";
}

.pe-7s-switch:before {
  content: "\e696";
}

.pe-7s-angle-down-circle:before {
  content: "\e689";
}

.pe-7s-scissors:before {
  content: "\e697";
}

.pe-7s-wallet:before {
  content: "\e600";
}

.pe-7s-safe:before {
  content: "\e698";
}

.pe-7s-volume2:before {
  content: "\e601";
}

.pe-7s-volume1:before {
  content: "\e602";
}

.pe-7s-voicemail:before {
  content: "\e603";
}

.pe-7s-video:before {
  content: "\e604";
}

.pe-7s-user:before {
  content: "\e605";
}

.pe-7s-upload:before {
  content: "\e606";
}

.pe-7s-unlock:before {
  content: "\e607";
}

.pe-7s-umbrella:before {
  content: "\e608";
}

.pe-7s-trash:before {
  content: "\e609";
}

.pe-7s-tools:before {
  content: "\e60a";
}

.pe-7s-timer:before {
  content: "\e60b";
}

.pe-7s-ticket:before {
  content: "\e60c";
}

.pe-7s-target:before {
  content: "\e60d";
}

.pe-7s-sun:before {
  content: "\e60e";
}

.pe-7s-study:before {
  content: "\e60f";
}

.pe-7s-stopwatch:before {
  content: "\e610";
}

.pe-7s-star:before {
  content: "\e611";
}

.pe-7s-speaker:before {
  content: "\e612";
}

.pe-7s-signal:before {
  content: "\e613";
}

.pe-7s-shuffle:before {
  content: "\e614";
}

.pe-7s-shopbag:before {
  content: "\e615";
}

.pe-7s-share:before {
  content: "\e616";
}

.pe-7s-server:before {
  content: "\e617";
}

.pe-7s-search:before {
  content: "\e618";
}

.pe-7s-film:before {
  content: "\e6a5";
}

.pe-7s-science:before {
  content: "\e619";
}

.pe-7s-disk:before {
  content: "\e6a6";
}

.pe-7s-ribbon:before {
  content: "\e61a";
}

.pe-7s-repeat:before {
  content: "\e61b";
}

.pe-7s-refresh:before {
  content: "\e61c";
}

.pe-7s-add-user:before {
  content: "\e6a9";
}

.pe-7s-refresh-cloud:before {
  content: "\e61d";
}

.pe-7s-paperclip:before {
  content: "\e69c";
}

.pe-7s-radio:before {
  content: "\e61e";
}

.pe-7s-note2:before {
  content: "\e69d";
}

.pe-7s-print:before {
  content: "\e61f";
}

.pe-7s-network:before {
  content: "\e69e";
}

.pe-7s-prev:before {
  content: "\e620";
}

.pe-7s-mute:before {
  content: "\e69f";
}

.pe-7s-power:before {
  content: "\e621";
}

.pe-7s-medal:before {
  content: "\e6a0";
}

.pe-7s-portfolio:before {
  content: "\e622";
}

.pe-7s-like2:before {
  content: "\e6a1";
}

.pe-7s-plus:before {
  content: "\e623";
}

.pe-7s-left-arrow:before {
  content: "\e6a2";
}

.pe-7s-play:before {
  content: "\e624";
}

.pe-7s-key:before {
  content: "\e6a3";
}

.pe-7s-plane:before {
  content: "\e625";
}

.pe-7s-joy:before {
  content: "\e6a4";
}

.pe-7s-photo-gallery:before {
  content: "\e626";
}

.pe-7s-pin:before {
  content: "\e69b";
}

.pe-7s-phone:before {
  content: "\e627";
}

.pe-7s-plug:before {
  content: "\e69a";
}

.pe-7s-pen:before {
  content: "\e628";
}

.pe-7s-right-arrow:before {
  content: "\e699";
}

.pe-7s-paper-plane:before {
  content: "\e629";
}

.pe-7s-delete-user:before {
  content: "\e6a7";
}

.pe-7s-paint:before {
  content: "\e62a";
}

.pe-7s-bottom-arrow:before {
  content: "\e6a8";
}

.pe-7s-notebook:before {
  content: "\e62b";
}

.pe-7s-note:before {
  content: "\e62c";
}

.pe-7s-next:before {
  content: "\e62d";
}

.pe-7s-news-paper:before {
  content: "\e62e";
}

.pe-7s-musiclist:before {
  content: "\e62f";
}

.pe-7s-music:before {
  content: "\e630";
}

.pe-7s-mouse:before {
  content: "\e631";
}

.pe-7s-more:before {
  content: "\e632";
}

.pe-7s-moon:before {
  content: "\e633";
}

.pe-7s-monitor:before {
  content: "\e634";
}

.pe-7s-micro:before {
  content: "\e635";
}

.pe-7s-menu:before {
  content: "\e636";
}

.pe-7s-map:before {
  content: "\e637";
}

.pe-7s-map-marker:before {
  content: "\e638";
}

.pe-7s-mail:before {
  content: "\e639";
}

.pe-7s-mail-open:before {
  content: "\e63a";
}

.pe-7s-mail-open-file:before {
  content: "\e63b";
}

.pe-7s-magnet:before {
  content: "\e63c";
}

.pe-7s-loop:before {
  content: "\e63d";
}

.pe-7s-look:before {
  content: "\e63e";
}

.pe-7s-lock:before {
  content: "\e63f";
}

.pe-7s-lintern:before {
  content: "\e640";
}

.pe-7s-link:before {
  content: "\e641";
}

.pe-7s-like:before {
  content: "\e642";
}

.pe-7s-light:before {
  content: "\e643";
}

.pe-7s-less:before {
  content: "\e644";
}

.pe-7s-keypad:before {
  content: "\e645";
}

.pe-7s-junk:before {
  content: "\e646";
}

.pe-7s-info:before {
  content: "\e647";
}

.pe-7s-home:before {
  content: "\e648";
}

.pe-7s-help2:before {
  content: "\e649";
}

.pe-7s-help1:before {
  content: "\e64a";
}

.pe-7s-graph3:before {
  content: "\e64b";
}

.pe-7s-graph2:before {
  content: "\e64c";
}

.pe-7s-graph1:before {
  content: "\e64d";
}

.pe-7s-graph:before {
  content: "\e64e";
}

.pe-7s-global:before {
  content: "\e64f";
}

.pe-7s-gleam:before {
  content: "\e650";
}

.pe-7s-glasses:before {
  content: "\e651";
}

.pe-7s-gift:before {
  content: "\e652";
}

.pe-7s-folder:before {
  content: "\e653";
}

.pe-7s-flag:before {
  content: "\e654";
}

.pe-7s-filter:before {
  content: "\e655";
}

.pe-7s-file:before {
  content: "\e656";
}

.pe-7s-expand1:before {
  content: "\e657";
}

.pe-7s-exapnd2:before {
  content: "\e658";
}

.pe-7s-edit:before {
  content: "\e659";
}

.pe-7s-drop:before {
  content: "\e65a";
}

.pe-7s-drawer:before {
  content: "\e65b";
}

.pe-7s-download:before {
  content: "\e65c";
}

.pe-7s-display2:before {
  content: "\e65d";
}

.pe-7s-display1:before {
  content: "\e65e";
}

.pe-7s-diskette:before {
  content: "\e65f";
}

.pe-7s-date:before {
  content: "\e660";
}

.pe-7s-cup:before {
  content: "\e661";
}

.pe-7s-culture:before {
  content: "\e662";
}

.pe-7s-crop:before {
  content: "\e663";
}

.pe-7s-credit:before {
  content: "\e664";
}

.pe-7s-copy-file:before {
  content: "\e665";
}

.pe-7s-config:before {
  content: "\e666";
}

.pe-7s-compass:before {
  content: "\e667";
}

.pe-7s-comment:before {
  content: "\e668";
}

.pe-7s-coffee:before {
  content: "\e669";
}

.pe-7s-cloud:before {
  content: "\e66a";
}

.pe-7s-clock:before {
  content: "\e66b";
}

.pe-7s-check:before {
  content: "\e66c";
}

.pe-7s-chat:before {
  content: "\e66d";
}

.pe-7s-cart:before {
  content: "\e66e";
}

.pe-7s-camera:before {
  content: "\e66f";
}

.pe-7s-call:before {
  content: "\e670";
}

.pe-7s-calculator:before {
  content: "\e671";
}

.pe-7s-browser:before {
  content: "\e672";
}

.pe-7s-box2:before {
  content: "\e673";
}

.pe-7s-box1:before {
  content: "\e674";
}

.pe-7s-bookmarks:before {
  content: "\e675";
}

.pe-7s-bicycle:before {
  content: "\e676";
}

.pe-7s-bell:before {
  content: "\e677";
}

.pe-7s-battery:before {
  content: "\e678";
}

.pe-7s-ball:before {
  content: "\e679";
}

.pe-7s-back:before {
  content: "\e67a";
}

.pe-7s-attention:before {
  content: "\e67b";
}

.pe-7s-anchor:before {
  content: "\e67c";
}

.pe-7s-albums:before {
  content: "\e67d";
}

.pe-7s-alarm:before {
  content: "\e67e";
}

.pe-7s-airplay:before {
  content: "\e67f";
}
