.contact-wrapper {
    background: -webkit-linear-gradient(left, #186b7a, #0E96AD);
}

.contact-form {
    background: #fff;
    margin-top: 10%;
    margin-bottom: 5%;
    width: 70%;
}

.contact-form .form-control {
    border-radius: 1rem;
}

.contact-image {
    text-align: center;
}

.contact-image img {
    border-radius: 6rem;
    width: 11%;
    margin-top: -3%;
    transform: rotate(29deg);
}

.contact-form .form {
    padding: 14%;
}

.contact-form .form .row {
    margin-bottom: -7%;
}

.contact-form h3 {
    margin-bottom: 8%;
    margin-top: -10%;
    text-align: center;
    color: #0E96AD;
}

.contact-form .btnContact {
    width: 50%;
    border: none;
    border-radius: 1rem;
    padding: 1.5%;
    background: #0E96AD;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}

.btnContact {
    width: 50%;
    border-radius: 1rem;
    padding: 1.5%;
    color: #fff;
    background-color: #0E96AD;
    border: none;
    cursor: pointer;
}